import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { fieldToTextField } from 'formik-material-ui';
import ListItemText from '@mui/material/ListItemText';
import { getDisabledForm } from '../../helpers/getDisabledForm';
import { List, ListItem, ListItemButton, TextField } from '@mui/material';

const domainSuggestions = [
  'mail.ru',
  'yandex.ru',
  'gmail.com',
  'rambler.ru',
  'inbox.ru',
  'bk.ru',
  'list.ru',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'internet.ru',
  'xmail.ru',
];

export const EmailField = (props) => {
  const { classes, inputProps, ...otherProps } = fieldToTextField(props);

  const { id } = useParams();
  const location = useLocation();
  const { account } = useSelector((state) => state.account);

  const [suggestions, setSuggestions] = useState([]);
  const [isListOpen, setIsListOpen] = useState(false);
  const [value, setValue] = useState(otherProps.value);

  const listRef = useRef(null);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (newValue.includes('@')) {
      setIsListOpen(true);
      const emailParts = newValue.split('@');
      const domain = emailParts[1];
      const filteredSuggestions = domainSuggestions.filter((suggestion) =>
        suggestion.startsWith(domain)
      );
      setSuggestions(filteredSuggestions);
    } else {
      setIsListOpen(false);
      setSuggestions([]);
    }

    props.form.setFieldValue(props.field.name, newValue);
  };

  const handleSuggestionClick = (suggestion) => {
    const newValue = `${value.split('@')[0]}@${suggestion}`;
    setValue(newValue);
    setIsListOpen(false);
    setSuggestions([]);

    props.form.setFieldValue(props.field.name, newValue);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setIsListOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const inputPropsWithAutoComplete = {
    ...inputProps,
    onChange: handleChange,
  };

  return (
    <>
      <TextField
        value={value}
        {...otherProps}
        classes={classes}
        autoComplete="off"
        name={otherProps.name}
        type={otherProps.type}
        InputProps={inputPropsWithAutoComplete}
        disabled={
          getDisabledForm(account, id, location.pathname) ?? false
        }
      />
      {isListOpen && suggestions.length > 0 && (
        <div ref={listRef}>
        <List
          sx={{
            zIndex: 1,
            borderRadius: '4px',
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
          }}
        >
          {suggestions.map((suggestion) => (
            <ListItem
              sx={{ background: 'white' }}
              disablePadding
              key={suggestion}
            >
              <ListItemButton onClick={() => handleSuggestionClick(suggestion)}>
                <ListItemText primary={suggestion} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        </div>
      )}
    </>
  );
};
