import Actions from "../../App/interface/actions";
import {
	AccountActionsTypes,
	IAccountState
} from "../interface/account";

const init: IAccountState = {
  account: null
};

export function AccountReducer(state: IAccountState = init, action: Actions): IAccountState {
  switch (action.type) {
    case AccountActionsTypes.FETCH_ACCOUNT:
      return {
        ...state,
        ...{
          account: action.payload
        }
      };
    case AccountActionsTypes.CLEAR:
      return {
        ...state,
        ...{
          account: null
        }
      };
    default:
      return state;
  }
}
