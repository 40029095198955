import { HttpService } from "../../../App/services/http"
import {
	IUserPost,
	IUserUpdate
} from "../interfaces/user"

export const UserService = {
  users,
  user,
  update,
  create,
}

function create(data: IUserPost) {
  return HttpService.post(`auth/register`, data)
    .then(
      response => {
        return response
      })
}

function users(params: any) {
  return HttpService.get(`auth/users`, params)
    .then(
      response => {
        return response
      })
}

function user(id: string) {
  return HttpService.get(`auth/users/idUser?userId=${id}`)
    .then(
      response => {
        return response
      })
}

function update(username: string, data: IUserUpdate) {
  return HttpService.patch(`auth/users/update?userName=${username}`, data)
    .then(
      response => {
        return response
      })
}
