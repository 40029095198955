import { ThemeProvider } from '@mui/material/styles'
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Alerts from "./App/components/Alerts";
import Loading from "./App/components/Loading";
import { store } from "./App/reducers/store";
import Routes from './App/routes';
import { theme } from "./App/styles/theme";
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Loading/>
        <Alerts/>
        <BrowserRouter>
          <Routes/>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.Fragment>
);
