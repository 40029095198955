import { IPage } from "../../App/interface/page";

const INFO_PAGE = {
	name: "Регистратор",
	path: "/patients/:id/info",
	id: "basic"
}

const BLOOD_COUNT_PAGE = {
	name: "Медсестра",
	path: "/patients/:id/blood-count",
	id: "blood"
}

const BREAST_ULTRASOUND_PAGE = {
	name: "УЗИ молочных желёз",
	path: "/patients/:id/breast-ultrasound",
	id: "uzi_mol"
}

const CANCER_SCREENING_PAGE = {
	name: "Онкоскрининг",
	path: "/patients/:id/cancer-screening",
	id: "onco"
}

const THYROID_ULTRASOUND_PAGE = {
	name: "УЗИ щитовидной железы",
	path: "/patients/:id/thyroid-ultrasound",
	id: "uzi_chit"
}

const ENDOCRINOLOGIST_PAGE = {
	name: "Эндокринолог",
	path: "/patients/:id/endocrinologist",
	id: "doc_endo"
}
const THERAPIST_PAGE = {
	name: "Терапевт",
	path: "/patients/:id/therapist",
	id: "doc_tera"
}
export const tabs = [
	INFO_PAGE,
	BLOOD_COUNT_PAGE,
	THYROID_ULTRASOUND_PAGE,
	BREAST_ULTRASOUND_PAGE,
	THERAPIST_PAGE,
	ENDOCRINOLOGIST_PAGE,
	CANCER_SCREENING_PAGE,
];
export const getTabs = (positionId: number, role: string): IPage[] => {
	switch (role) {
		case 'MedicalStaff':
			switch (positionId) {
				case 1:
					return [BLOOD_COUNT_PAGE];
				case 2:
					return [THYROID_ULTRASOUND_PAGE, BREAST_ULTRASOUND_PAGE];
				case 3:
					return [ENDOCRINOLOGIST_PAGE];
				case 4:
					return [THERAPIST_PAGE, CANCER_SCREENING_PAGE];
				default:
					return []
			}
		case 'Receptionist':
			return [INFO_PAGE]
		case 'Admin':
			return [
				INFO_PAGE,
				BLOOD_COUNT_PAGE,
				THYROID_ULTRASOUND_PAGE,
				BREAST_ULTRASOUND_PAGE,
				THERAPIST_PAGE,
				ENDOCRINOLOGIST_PAGE,
				CANCER_SCREENING_PAGE,
			];
		default:
			return []
	}
}

export const getPatientTabs = (gender: string): IPage[] => {
	switch (gender) {
		case 'Female':
			return [
				INFO_PAGE,
				BLOOD_COUNT_PAGE,
				THYROID_ULTRASOUND_PAGE,
				BREAST_ULTRASOUND_PAGE,
				THERAPIST_PAGE,
				ENDOCRINOLOGIST_PAGE,
				CANCER_SCREENING_PAGE,
			];
		case 'Male':
			return [
				INFO_PAGE,
				BLOOD_COUNT_PAGE,
				THYROID_ULTRASOUND_PAGE,
				THERAPIST_PAGE,
				ENDOCRINOLOGIST_PAGE,
				CANCER_SCREENING_PAGE,
			];
		default:
			return []
	}
}
