import { IAccount } from "../../Account/interface/account"
import { IPatient } from "../../features/Patient/interfaces/patient";
import { IUser } from "../../features/User/interfaces/user";

const getFullName = (account: IAccount | IPatient | IUser | null) => {
  if ( account ) {
  return account?.middleName
      ? `${account?.lastName} ${account?.firstName} ${account?.middleName}`
      : `${account?.lastName} ${account?.firstName}`
  }
}
export { getFullName };