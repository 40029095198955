import {
	useEffect,
	useState
} from "react"
import {
	useDispatch,
	useSelector
} from "react-redux";
import {
	NavigateFunction,
	useNavigate
} from "react-router";
import { AccountActions } from "../Account/actions/account";
import { IAccount } from "../Account/interface/account";
import { AlertActionsTypes } from "../App/interface/alert";
import { IRootState } from "../App/reducers/store";
import * as AppStorage from "../App/services/storage"
import { AuthActions } from "./actions/auth";

export default function Account(props: {
	children: JSX.Element,
	positionIds?: number[],
	roles?: string[]
}): JSX.Element | null {
	const dispatch: any = useDispatch();
	const {account} = useSelector((state: IRootState) => state.account)

	const {children, roles, positionIds} = props
	const navigate: NavigateFunction = useNavigate();
	const [allowed, setAllowed] = useState(false);
	const permisionHandler = (user: IAccount) => {
		if (user?.role === 'Admin'){
			setAllowed(true);
		}else{
			if ((roles || positionIds)) {
				if ((roles?.find((role) => role === user?.role) || positionIds?.find((positionId) => positionId === user?.idFunction))) {
					setAllowed(true);
				} else {
					dispatch({
						type: AlertActionsTypes.ERROR, payload: {
							message: 'Страница не найдена или удалена!',
							type: 'error'
						}
					})
					navigate('/patients/list');
				}
			}
		}
	}
	// eslint-disable-next-line
	useEffect(() => {
		const token: string | null = AppStorage.get('token');

		if (account) {
			if ((roles || positionIds)) {
				permisionHandler(account)
			} else {
				setAllowed(true);
			}
		} else if (token) {
			const getAccount = async () => {
				return await dispatch(AccountActions.account());
			}

			getAccount().then(
			  (response) => {
				  permisionHandler(response)
			  },
			  () => dispatch(AuthActions.logout()).then(() => navigate('/'))
			)
		} else {
			navigate('/login');
		}
	});


	return allowed ? children : null
}
