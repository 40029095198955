import { HttpService } from "../../../App/services/http"
import {
	IThyroid,
	IThyroidInput
} from "../interfaces/thyroid"

export const ThyroidService = {
  thyroid,
  update,
  create,
}

function create(data: IThyroidInput) {
  return HttpService.post('medical-researches/ultrasound-thyroid', data)
    .then(
      response => {
        return response
      })
}

function thyroid(id: number) {
  return HttpService.get(`medical-researches/ultrasound-thyroid/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IThyroid) {
  return HttpService.patch('medical-researches/ultrasound-thyroid', data)
    .then(
      response => {
        return response
      })
}
