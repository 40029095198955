export interface ILocality {
  name: string,
  nameParentArea: string,
  nameParentRegion?: string,
  level: number,
  objectId: number | null | undefined,
  isActual: boolean,
  isActive: boolean,
}

export interface ILocalityState {
  localities: Array<ILocality> | [],
  locality: ILocality | null,
}

export enum LocalityActionsTypes {
  LOCALITIES = 'LOCALITIES',
  LOCALITY = 'LOCALITY',
}
