import { HttpService } from "../../../App/services/http"

export const FileService = {
  file,
  files,
  remove,
  sendFile,
}

function sendFile(params: any, values: any) {
  return HttpService.post(`files?idPatient=${params.idPatient}&type=${params.type}`, values, true, true).then(
    response => {
      return response
    }
  )
}

function files(id: number) {
  return HttpService.get(`files/${id}`).then(
    response => {
      return response
    }
  )
}

function file(id: number) {
  return HttpService.get(`files/file/${id}`, {}, true, true).then(
    response => {
      return response
    }
  )
}

function remove(id: number) {
  return HttpService.delete(`files/file/${id}`).then(
    response => {
      return response
    }
  )
}

