import EmailIcon from '@mui/icons-material/Email';
import PrintIcon from '@mui/icons-material/Print';
import {
	TabContext,
	TabList,
	TabPanel
} from "@mui/lab";

import {
	Button,
	Card,
	CardContent,
	Grid,
	Tab,
	Tooltip,
	Typography
} from '@mui/material';
import Box from "@mui/material/Box";
import { tabsClasses } from '@mui/material/Tabs';
import { makeStyles } from "@mui/styles";
import {
	useEffect,
	useState
} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import { useParams } from 'react-router';
import {
	Outlet,
	useLocation,
	useNavigate
} from "react-router-dom";
import { IRootState } from "../../../App/reducers/store";
import { PatientActions } from "../../../features/Patient/actions/patient";
import { tabs, getPatientTabs} from '../../../shared/data/PatientPageTabs';
import { DirtyActionsTypes } from '../../../App/interface/dirty';

const useStyles = makeStyles(() => ({
	patient: {},
	container: {
		width: "100%",
		height: "100%",
		background: "#F9FAFA",
		padding: "24px 0px 0px 24px;"
	},
	icon: {
		height: "16px",
		width: "16px",
		display: "block",
		marginTop: "2px",
		marginRight: "5px"
	},
	tab_list: {
		backgroundColor: "white",
		borderBottom: "1px solid #DBDEDF",
		color: "#495055",
		height: "50px",
		'&.Mui-focusVisible': {
			backgroundColor: 'rgba(100, 95, 228, 0.32)',
		},
		'& .MuiTabs-indicator': {
			backgroundColor: '#2595FF',
			borderRadius: "4px 4px 0px 0px",
			height: "4px"
		},
	},
	tab: {
		paddingBottom: "35px",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: "14px",
		textTransform: "none",
		'&.Mui-selected': {
			color: '#2595FF',
		},
	},
	tab_panel: {
		padding: 0
	}
}));

const BASIC_TAB = 'basic'

const PatientProxy = () => {
	const {id} = useParams();
	const dispatch: any = useDispatch();

	const {dirty} = useSelector((state: IRootState) => state.dirty)
	const {account} = useSelector((state: IRootState) => state.account)
	const {patient} = useSelector((state: IRootState) => state.patients)
	const location = useLocation();
	const [tabId, setTabId] = useState(() => {
		for (let i = 0; i < tabs.length; i++) {
			if (location.pathname === tabs[i].path.replace(':id', id ?? '')) {
				return tabs[i].id
			}
		}
		return BASIC_TAB;
	});
	const navigate = useNavigate()
	const classes = useStyles();

	const handleChangeTabValue = (event: any, newValue: any) => {
		setTabId(newValue);
		let targetPage = '';
	
		switch (newValue) {
			case "basic":
				targetPage = "/info";
				break;
			case "blood":
				targetPage = "/blood-count";
				break;
			case "onco":
				targetPage = "/cancer-screening";
				break;
			case "uzi_chit":
				targetPage = "/thyroid-ultrasound";
				break;
			case "uzi_mol":
				targetPage = patient?.gender === 'Male' ? "/info" : "/breast-ultrasound";
				break;
			case "doc_endo":
				targetPage = "/endocrinologist";
				break;
			case "doc_tera":
				targetPage = "/therapist";
				break;
			default:
				break;
		}
	
		if (targetPage) {
			dirty ? handleTabClick(targetPage) : navigate(`/patients/${id}${targetPage}`);
		}
	}
	
	const handleTabClick = (targetPage: string) => {
		const confirmed = window.confirm('Перейти на эту страницу без сохранения данных?');
		if (confirmed) {
				dispatch({type: DirtyActionsTypes.DIRTY, payload: false})
				navigate(`/patients/${id}${targetPage}`);
		} else {
			setTabId(() => {
				for (let i = 0; i < tabs.length; i++) {
					if (location.pathname === tabs[i].path.replace(':id', id ?? '')) {
						return tabs[i].id
					}
				}
				return BASIC_TAB;
			})
		}
	};

	useEffect(() => {
		let match = window.location.pathname.match(/^\/patients\/\d+$/g);
		if (match && match.length === 1) {
			navigate(`/patients/${id}/info`, {replace: true})
		}
	}, [])

	const sendConclusion = () => {
		dispatch(PatientActions.sendConclusion(parseInt(id ?? ''))).then(
		  () => {
		  },
		  () => {
		  }
		)
	}

	const printConclusion = () => {
		dispatch(PatientActions.printConclusion(parseInt(id ?? ''))).then(
		  () => {
		  },
		  () => {
		  }
		)
	}

	const printPersonalInformation = () => {
		dispatch(PatientActions.printPersonalInformation(parseInt(id ?? ''))).then(
		  () => {
		  },
		  () => {
		  }
		)
	}

	return (id) ? (
	  <>
		  <Typography variant="h5" className={'pageTitle'}>
			  Карта пациента <b>{patient?.lastName} {patient?.firstName} {patient?.middleName} </b>
		  </Typography>
		  {["Admin", "Receptionist"].indexOf(account?.role ?? '') !== -1 &&
              <Grid container sx={{padding: '0px 0 10px 0'}}>
                  <Grid item sx={{paddingRight: '15px'}}>
                      <Button variant="text" startIcon={<PrintIcon/>} color="success" onClick={printConclusion}>
                          Печать
                      </Button>
                  </Grid>
                  <Grid item sx={{paddingRight: '15px'}}>
										<Button variant="text" startIcon={<PrintIcon/>} color="success"
														onClick={printPersonalInformation}>
												Печать согласия на обработку данных
										</Button>
                  </Grid>
                  <Grid item>
										<Tooltip title={!patient?.email ? "Для отправки заключения на почту, заполните поле 'Email'" : null}>
											<Grid>
												<Button
													variant="text"
													color="success"
													startIcon={<EmailIcon/>}
													onClick={sendConclusion}
													disabled={!patient?.email}
													>
														Отправить заключение
												</Button>
											</Grid>
										</Tooltip>
                  </Grid>
              </Grid>}
		  <Card>
			  <CardContent>
				  <TabContext value={tabId ?? BASIC_TAB}>
					  <Box>
						  <TabList
							className={classes.tab_list}
							variant="scrollable"
							onChange={handleChangeTabValue}
							aria-label="Пункт меню"
							sx={{
								[`& .${tabsClasses.scrollButtons}`]: {
									'&.Mui-disabled': {opacity: 0.3},
								},
							}}
						  >
							  {patient && getPatientTabs(patient.gender).map((tab) => (
								<Tab
								  className={classes.tab}
								  label={tab.name}
								  value={tab.id}
								  key={tab.id}
								/>
							  ))}
						  </TabList>
					  </Box>
					  <TabPanel className={classes.tab_panel} value={tabId ?? BASIC_TAB}>
						  <Outlet/>
					  </TabPanel>
				  </TabContext>
			  </CardContent>
		  </Card>
	  </>
	) : null;
};

export default PatientProxy;
