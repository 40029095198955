export interface IAccount {
  id: string,
  name: string,
  firstName: string,
  lastName: string,
  middleName: string,
  idFunction: number,
  email: string,
  role: string,
  userName: string
}

export interface IAccountState {
  account: IAccount | null
}

export enum AccountActionsTypes {
  FETCH_ACCOUNT = 'FETCH_ACCOUNT',
  CLEAR = 'CLEAR'
}