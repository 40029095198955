import Actions from "../../../App/interface/actions";
import {
	IThyroidState,
	ThyroidActionsTypes
} from "../interfaces/thyroid";


const init: IThyroidState = {
  thyroid: null,
};

export function ThyroidReducer(state: IThyroidState = init, action: Actions): IThyroidState {
  switch (action.type) {
    case ThyroidActionsTypes.THYROID:
      return {
        ...state,
        ...{
          thyroid:  action.payload
        }
      };
    default:
      return state;
  }
}