import { Dispatch } from "react"
import { AlertActionsTypes } from "../../../App/interface/alert"
import { LoadingActionsTypes } from "../../../App/interface/loading"
import { McbActionsTypes } from "../interfaces/mcb";
import { McbService } from "../services/mcb"


export const McbActions = {
	mcbs,
}

function mcbs(data: any) {
	return (dispatch: Dispatch<object>) => new Promise<void>((resolve, reject) => {
		dispatch({type: LoadingActionsTypes.LOADING, payload: true})
		return McbService.mcbs(data)
		  .then(
			response => {
				dispatch({type: LoadingActionsTypes.LOADING, payload: false})
				dispatch({type: McbActionsTypes.MCB, payload: response})
				resolve(response)
			},
			error => {
				dispatch({type: LoadingActionsTypes.LOADING, payload: false})
				dispatch({
					type: AlertActionsTypes.ERROR, payload: {
						message: error.message,
						type: 'error'
					}
				})
				reject(error.message)
			}
		  )
	})
}

  















