import { IMcb } from "../../Mcb/interfaces/mcb";

export interface IEndocrinologistInput {
	idPatient: number,
	mcbIds: number[],
	recommendations: string
}

export interface IEndocrinologist {
	id: number,
	mcbs: IMcb[],
	idUser: string,
	createdAt: Date,
	idPatient: number,
	recommendations: string,
}

export interface IEndocrinologistUpdate {
	id: number,
	mcbIds: number[],
	idPatient: number,
	recommendations: string,
}

export interface IEndocrinologistState {
	endocrinologist: IEndocrinologist | null,
}

export enum EndocrinologistActionsTypes {
	ENDOCRINOLOGIST = 'ENDOCRINOLOGIST',
}
