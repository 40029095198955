import Actions from "../../../App/interface/actions";
import {
	FileActionsTypes,
	IFileState
} from "../interfaces/file";

const init: IFileState = {
  file: null,
  files: null,
};

export function FileReducer(state: IFileState = init, action: Actions): IFileState {
  switch (action.type) {
    case FileActionsTypes.FILES:
      return {
        ...state,
        ...{
          files:  action.payload
        }
      };

    case FileActionsTypes.FILE:
      return {
        ...state,
        ...{
          file:  action.payload
        }
      };
    default:
      return state;
  }
}