import {
  applyMiddleware,
  combineReducers,
  createStore
} from 'redux';
import logger from "redux-logger";
import thunk, { ThunkDispatch } from "redux-thunk";
import { IAccountState } from "../../Account/interface/account";
import { AccountReducer } from "../../Account/reducers/account";
import { IBloodTestState } from "../../features/Analysis/interfaces/bloodTest";
import { BloodTestReducer } from "../../features/Analysis/reducer/bloodTest";
import { IBreastUltrasoundState } from '../../features/Researches/interfaces/breastUltrasound';
import { BreastUltrasoundReducer } from '../../features/Researches/reducers/breastUltrasound';
import { IEndocrinologistState } from '../../features/Conclusions/interfaces/endocrinologist';
import { ITherapistState } from '../../features/Conclusions/interfaces/therapist';
import { EndocrinologistReducer } from '../../features/Conclusions/reducers/endocrinologist';
import { TherapistReducer } from '../../features/Conclusions/reducers/therapist';
import { IFileState } from '../../features/Files/interfaces/file';
import { FileReducer } from '../../features/Files/reducers/file';
import { ILocalityState } from "../../features/Localities/interfaces/localities";
import { LocalityReducer } from "../../features/Localities/reducer/locality";
import { IMcbState } from "../../features/Mcb/interfaces/mcb";
import { McbReducer } from "../../features/Mcb/reducer/mcb";
import { IPatientState } from '../../features/Patient/interfaces/patient';
import { PatientReducer } from '../../features/Patient/reducer/patient';
import { ICancerScreeningState } from '../../features/Researches/interfaces/cancerScreening';
import { IThyroidState } from '../../features/Researches/interfaces/thyroid';
import { CancerScreeningReducer } from '../../features/Researches/reducers/cancerScreening';
import { ThyroidReducer } from '../../features/Researches/reducers/thyroid';
import { IStreetState } from '../../features/Streets/interfaces/streets';
import { StreetReducer } from '../../features/Streets/reducer/streets';
import { IUserState } from '../../features/User/interfaces/user';
import { UserReducer } from '../../features/User/reducer/user';
import { IAlertsState } from "../interface/alert";
import { ILoadingState } from "../interface/loading";
import { AlertReducer } from "./alert";
import { LoadingReducer } from "./loading";
import { DirtyReducer } from './dirty';
import { IDirtyState } from '../interface/dirty';

const rootReducer = combineReducers<IRootState>({
  breastUltrasound: BreastUltrasoundReducer,
  endocrinologist: EndocrinologistReducer,
  cancerScreening: CancerScreeningReducer,
  therapist: TherapistReducer,
  patients: PatientReducer,
  account: AccountReducer,
  loading: LoadingReducer,
  thyroid: ThyroidReducer,
  alerts: AlertReducer,
  bloodTest: BloodTestReducer,
  localities: LocalityReducer,
  streets: StreetReducer,
  dirty: DirtyReducer,
  users: UserReducer,
  files: FileReducer,
  mcbs: McbReducer,
});

export interface IRootState {
  breastUltrasound: IBreastUltrasoundState,
  endocrinologist: IEndocrinologistState,
  cancerScreening: ICancerScreeningState,
  patients: IPatientState,
  account: IAccountState,
  loading: ILoadingState,
  thyroid: IThyroidState,
  alerts: IAlertsState,
  bloodTest: IBloodTestState,
  localities: ILocalityState,
  therapist: ITherapistState,
  streets: IStreetState,
  dirty: IDirtyState,
  users: IUserState,
  files: IFileState,
  mcbs: IMcbState,
}

export type RootState = ReturnType<typeof rootReducer>;
type AppAction = ReturnType<typeof store.dispatch>;

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk, logger),
);

export type Dispatch = ThunkDispatch<RootState, never, AppAction>;
