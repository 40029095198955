import { AccountActionsTypes } from "../../Account/interface/account";
import { AlertActionsTypes } from "../../App/interface/alert";
import * as AppStorage from "../../App/services/storage";
import { ILoginState } from "../interfaces/login";
import { AuthServices } from "../services/auth";

export const AuthActions = {
  login,
  logout
}

function login(data: ILoginState) {
  return (dispatch: any) => new Promise((resolve, reject) => {
    return AuthServices.login(data)
      .then(
        response => {
          AppStorage.set('token', response.token)
          resolve(response)
        },
        error => {
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              title: "Авторизация",
              message: error.message,
            }
          })
          reject(error)
        }
      )
  })
}

function logout() {
  return async (dispatch: any) => {
    AppStorage.clear()
    dispatch({type: AccountActionsTypes.FETCH_ACCOUNT, payload: null})
  }
}
