export interface IDirtyState {
  dirty: boolean;
}

export interface IDirtyAction {
  type: string,
  payload: boolean
}

export enum DirtyActionsTypes {
  DIRTY = 'DIRTY'
}
