import { HttpService } from "../../../App/services/http"
import { IPatientInput } from "../interfaces/input/patient"

export const PatientService = {
  patients,
  patient,
  update,
  create,
  sendConclusion,
  printConclusion,
  printPersonalInformation,
}

function create(data: IPatientInput) {
  return HttpService.post(`patients`, data)
    .then(
      response => {
        return response
      })
}

function patients(params: any) {
  return HttpService.get(`patients`, params)
    .then(
      response => {
        return response
      })
}

function patient(id: number) {
  return HttpService.get(`patients/${id}`)
    .then(
      response => {
        return response
      })
}

function update(id: number, data: IPatientInput) {
  return HttpService.patch(`patients`, data)
    .then(
      response => {
        return response
      })
}

function sendConclusion(id: number) {
    return HttpService.post(`documents/conclusion/sending/queue/${id}`)
      .then(
        response => {
            return response
        })
}

function printConclusion(id: number) {
    return HttpService.get(`documents/conclusion/${id}`, {}, true, true)
      .then(
        response => {
            return response
        })
}
function printPersonalInformation(id: number) {
    return HttpService.get(`documents/consent-personal-data/${id}`, {}, true, true)
      .then(
        response => {
            return response
        })
}
