import React, { Suspense } from "react"
import { useRoutes } from "react-router";
import { Profile } from "../Account/components/Profile";
import Account from "../Auth/Account";
import Login from "../Auth/Login";
import Logout from "../Auth/Logout";
import { BloodCountPage } from "../features/Analysis/components";
import Endocrinologist from "../features/Conclusions/components/Endocrinologist";
import Therapist from "../features/Conclusions/components/Therapist";
import Patient from "../features/Patient/components/Patient";
import Thyroid from "../features/Researches/components/Thyroid";
import { User } from "../features/User/components/User";
import { AdminProxyPage } from "../pages/AdminProxyPage";
import { AdminRegistrationPage } from "../pages/AdminRegistrationPage";
import AdminRolesPage from "../pages/AdminRegistrationPage/component/AdminRegistrationPage";
import { AdminUsersPage } from "../pages/AdminUsersPage";
import { AuditPage } from "../pages/AuditPage";
import { ErrorPage } from "../pages/ErrorPage";
import PatientProxy from "../pages/PatientProxy/components/PatientProxy";
import Patients from "../pages/Patients/component/Patients";
import { PatientsPage } from "../pages/PatientsPage";
import { RegistrationPage } from "../pages/RegistrationPage";
import { App } from "./App";
import CancerScreening from "../features/Researches/components/CancerScreening";
import BreastUltrasound from "../features/Researches/components/breastUltrasound";

export default function Routes(): JSX.Element {
	const routing = useRoutes([
		{
			path: '/',
			element: <Account><App/></Account>,
			children: [
				// {
				// 	path: 'user',
				// 	element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account><UserPage/></Account></Suspense>
				// },
				{
					path: '/profile',
					element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account><Profile/></Account></Suspense>
				},
				{
					path: "patients",
					element: <Suspense
					  fallback={<div className="loading">Загружается...</div>}><Account><PatientsPage/></Account></Suspense>,
					children: [
						{
							path: "list",
							element: <Suspense
							  fallback={<div className="loading">Загружается...</div>}><Account><Patients/></Account></Suspense>,
						},
						{
							path: "registration",
							element: <Suspense
							  fallback={<div className="loading">Загружается...</div>}><Account><RegistrationPage/></Account></Suspense>,
						},
						{
							path: ":id",
							element: <Suspense
							  fallback={<div className="loading">Загружается...</div>}><Account><PatientProxy/></Account></Suspense>,
							children: [
								{
									path: "info",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["Receptionist", "MedicalStaff"]}
									  positionIds={[1, 2, 3, 4]}><Patient/></Account></Suspense>,
								},
								{
									path: "blood-count",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[1]}><BloodCountPage/></Account></Suspense>,
								},
								{
									path: "thyroid-ultrasound",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[2]}><Thyroid/></Account></Suspense>,
								},
								{
									path: "breast-ultrasound",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[2]}><BreastUltrasound/></Account></Suspense>,
								},
								{
									path: "cancer-screening",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[2]}><CancerScreening/></Account></Suspense>,
								},
								{
									path: "endocrinologist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]}
									  positionIds={[3]}><Endocrinologist/></Account></Suspense>,
								},
								{
									path: "therapist",
									element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
									  roles={["MedicalStaff", "Receptionist"]} positionIds={[4]}><Therapist/></Account></Suspense>,
								},
							]
						},
					]
				},
				{
					path: 'admin',
					element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account roles={["Admin"]}><AdminProxyPage/></Account></Suspense>,
					children: [
						{
							path: "registration",
							element: <Suspense fallback={
								<div className="loading">Загружается...</div>}><Account><AdminRegistrationPage/></Account></Suspense>,
						},
						{
							path: "users",
							element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account><AdminUsersPage/></Account></Suspense>,
						},
						{
							path: "user/edit/:id",
							element: <Suspense
							  fallback={<div className="loading">Загружается...</div>}><Account><User/></Account></Suspense>,
						},
						{
							path: "roles",
							element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account><AdminRolesPage/></Account></Suspense>,
						},

					]
				},
				{
					path: 'audit',
					element: <Suspense fallback={<div className="loading">Загружается...</div>}><Account
					  roles={["Admin"]}><AuditPage/></Account></Suspense>
				},
			]
		},
		{path: '/login', element: <Login/>},
		{path: '/logout', element: <Logout/>},
		{path: '*', element: <ErrorPage/>}
	]);

	return <React.Fragment>{routing}</React.Fragment>;
}
