import Actions from "../../../App/interface/actions";
import { CancerScreeningActionsTypes, ICancerScreeningState } from "../interfaces/cancerScreening";


const init: ICancerScreeningState = {
  cancerScreening: null,
};

export function CancerScreeningReducer(state: ICancerScreeningState = init, action: Actions): ICancerScreeningState {
  switch (action.type) {
    case CancerScreeningActionsTypes.CANCER_SCREENING:
      return {
        ...state,
        ...{
          cancerScreening:  action.payload
        }
      };
    default:
      return state;
  }
}