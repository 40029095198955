import { HttpService } from "../../../App/services/http"
import { ICancerScreening, ICancerScreeningInput } from "../interfaces/cancerScreening"

export const CancerScreeningService = {
  cancerScreening,
  update,
  create,
}

function create(data: ICancerScreeningInput) {
  return HttpService.post(`medical-researches/cancer-screening`, data)
    .then(
      response => {
        return response
      })
}

function cancerScreening(id: number) {
  return HttpService.get(`medical-researches/cancer-screening/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: ICancerScreening) {
  return HttpService.patch(`medical-researches/cancer-screening`, data)
    .then(
      response => {
        return response
      })
}