import Actions from "../../../App/interface/actions";
import {
	IMcbState,
	McbActionsTypes
} from "../interfaces/mcb";


const init: IMcbState = {
  mcbs: [],
};

export function McbReducer(state: IMcbState = init, action: Actions): IMcbState {
  switch (action.type) {
    case McbActionsTypes.MCB:
      return {
        ...state,
        ...{
          mcbs:  action.payload
        }
      };
    default:
      return state;
  }
}