import { HttpService } from "../../App/services/http"

export const AccountService = {
  account
}

function account() {
  return HttpService.get("/auth/account")
    .then(
      response => {
        return response
      })
}