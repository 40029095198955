import { IAccount } from "../../Account/interface/account"
import { getTabs } from "../../shared/data/PatientPageTabs";
import { IPage } from "../interface/page";

const getDisabledForm = (account: IAccount | null, id: string, pathname: string): null | boolean => {
	if (account?.role === "MedicalStaff") {
		const tabs = getTabs(account?.idFunction, account?.role)?.map((tab: IPage) => {
			tab.path = tab.path.replace(':id', id);
			return tab
		});
		for (let i = 0; i < tabs.length; i++) {
			if (tabs[i].path === pathname) {
				return false;
			}
		}
		return true;
	}
	return null
}
export { getDisabledForm };