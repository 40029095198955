import { useEffect } from "react";
import {
	Outlet,
	useNavigate
} from 'react-router-dom';
import Header from '../shared/components/Header/Header';

import './styles/index.scss';

export const App = () => {
    const navigate = useNavigate()
    useEffect(() => {
        if (window.location.pathname === '/') {
            navigate('/patients/list', {replace: true})
        }
    }, [])
    return (
        <>
            <Header/>
            <Outlet/>
        </>
    );
};
