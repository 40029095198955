import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	palette: {
		primary: {
			main: '#3668BF',
			dark: '#3e79e5',
		},
		error: {
			main: '#F44336',
			dark: '#d32f2f',
		},
		warning: {
			main: '#FF9800',
			dark: '#ff9e00',
		},
		success: {
			main: '#00C851',
			dark: '#00a64d'
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				containedSuccess: {
					backgroundColor: '#2695ff',
					textTransform: "none",
					color: 'white',
					'&:hover': {
						backgroundColor: '#2695ff',
					},
				},
				containedError: {
					textTransform: "none",
				},
				textSuccess: {
					color: '#2695ff',
					'&:hover': {
						backgroundColor: 'rgb(38 149 255 / 30%)'
					},
				}
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					'&.MuiChip-root': {
						height: '24px',
						borderRadius: '2px'
					}
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					'&.MuiPopover-paper': {
						borderRadius: '2px'
					}
				}
			}
		}
	}
});

