import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { AuthActions } from "./actions/auth";
import {
  Button,
  Grid,
  Paper,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

const RootContainer = styled('div')({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#edeef0',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: 400,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Title = styled('h2')({
  textAlign: 'center',
});

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const handleLogin = async () => {
    await dispatch(AuthActions.login({
      username,
      password
    })).then(
      () => {
        navigate('/patients');
      },
      () => {
      }
    )
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <RootContainer>
      <StyledPaper>
        <Title>Авторизация</Title>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <StyledTextField
                type="text"
                label="Логин"
                variant="outlined"
                value={username}
                onChange={handleEmailChange}
                fullWidth
              />
              <StyledTextField
                type="password"
                label="Пароль"
                variant="outlined"
                value={password}
                onChange={handlePasswordChange}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Войти
              </Button>
            </Grid>
          </Grid>
        </form>
      </StyledPaper>
    </RootContainer>
  );
};

export default Login;