import { IPage } from "../../App/interface/page";

const PATIENTS_PAGE = {
	name: "Реестр пациентов",
	path: "/patients/list",
	parentPath: "/patients"
}
// const AUDIT_PAGE = {
// 	name: "Отчетность",
// 	path: "/audit"
// }
const ADMIN_PAGE = {
	name: "Админ панель",
	path: "/admin/users",
    parentPath: "/admin"
}
export const getHeaderTabs = (role: string): IPage[] => {
  switch (role) {
    case 'MedicalStaff':
      return [PATIENTS_PAGE];
    case 'Receptionist':
      return [PATIENTS_PAGE];
    case 'Admin':
      return [
        PATIENTS_PAGE,
        // AUDIT_PAGE,
        ADMIN_PAGE
      ]
    default:
      return []
  }
}