const convertToLocalTimeToUTC = (localDateStr: string) => {
  if ( localDateStr ) {
    const localDate = new Date(localDateStr);
    const timeZoneOffsetMs = localDate.getTimezoneOffset() * 60 * 1000;
    const utcTimestamp = localDate.getTime() - timeZoneOffsetMs;
    const utcDate = new Date(utcTimestamp);
    const formattedDate = utcDate.toISOString();
  
    return formattedDate;
  }
}

const convertToDate = (dateString: Date) => {
  if (dateString) {
    const { format } = require('date-fns');
    const date = new Date(dateString);
    return format(date, 'dd.MM.yyyy');
  }
}


export {
  convertToLocalTimeToUTC,
  convertToDate
};

