export interface IBloodTest {
  id: number;
  idPatient: number;
  idUser: string;
  glucose: string;
  cholesterol: string;
  glycatedHemoglobin: string;
  hemoglobin: string;
  diabetesCalculationValue: number;
  height: string;
  weight: string;
  arterialPressure: string;
  heartRate: string;
  po2: string;
  vgdOd: string;
  vgdOs: string;
  waistCircumference: string;
  bodyIndex?: number;
}

export interface IBloodTestState {
  bloodTest: IBloodTest | null;
}

export enum BloodTestActionsTypes {
  BLOOD_TEST = 'BLOOD_TEST',
}
