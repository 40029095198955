/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Grid,
	InputLabel,
	Stack
} from '@mui/material';
import {
	Field,
	Form,
	Formik
} from 'formik';
import React, {
	useEffect,
	useState
} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import {
	useLocation,
	useNavigate,
	useParams
} from 'react-router-dom';

import { CustomDatePicker } from '../../../App/components/Input/CustomDatePicker';
import { TextFieldWithError } from '../../../App/components/Input/TextField';
import { getDisabledForm } from "../../../App/helpers/getDisabledForm";
import { getFullName } from '../../../App/helpers/getFullName';
import { textFieldProps } from '../../../App/helpers/textField';
import { IRootState } from '../../../App/reducers/store';

import styles from "../../../shared/styles/form";
import { BreastUltrasoundActions } from '../actions/breastUltrasound';
import {
	IBreastUltrasound,
	IBreastUltrasoundInput
} from '../interfaces/breastUltrasound';
import { DirtyActionsTypes } from '../../../App/interface/dirty';
import handleNavigate from '../../../App/helpers/clearButtonNavigate';

const useStyles = styles;

const BreastUltrasound = () => {
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();
	const {id} = useParams();
	
	const { dirty } = useSelector((state: IRootState) => state.dirty);
	const {account} = useSelector((state: IRootState) => state.account);
	const {breastUltrasound} = useSelector((state: IRootState) => state.breastUltrasound);
	const [loading, setLoading] = useState(false);
	const disableEdit = getDisabledForm(account, id ?? '', location.pathname) ?? false;

	const getBreastUltrasound = async (id: number) => {
		await dispatch(BreastUltrasoundActions.breastUltrasound(id));
	};

	const navigateToInfo = () => {
		navigate(`/patients/${id}/info`);
  };

	useEffect(() => {
		if (!loading && id) {
			getBreastUltrasound(parseInt(id)).then(
			  () => setLoading(true),
			  () => setLoading(true)
			);
		}
	}, [loading]);

	useEffect(() => {
		if (loading) {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		const beforeUnloadHandler = function () {
      return true;
    };
  
    if (dirty) {
      window.onbeforeunload = beforeUnloadHandler;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
	}, [dirty])

	const submit = (values: any) => {
		const data = {
			...values,
			...{
				id: breastUltrasound?.id,
				idUser: account?.id,
				idPatient: Number(id),
				createdAt: breastUltrasound?.createdAt,
			},
		};
		dispatch(
		  breastUltrasound
			? BreastUltrasoundActions.update(data as IBreastUltrasound)
			: BreastUltrasoundActions.create(data as IBreastUltrasoundInput)
		).then(
		  () => {
				dispatch({type: DirtyActionsTypes.DIRTY, payload: false})
		  },
		  () => {
		  }
		);
	};

	return loading ? (
	  <Grid className={classes.main}>
		  <Formik
			initialValues={{
				idPatient: breastUltrasound?.idPatient,
				conclusion: breastUltrasound?.conclusion,
				recommendations: breastUltrasound?.recommendations,
				user: getFullName(account),
			}}
			onSubmit={submit}
		  >
			  {({setFieldValue, dirty}) => {
					 // eslint-disable-next-line react-hooks/rules-of-hooks
					 useEffect(() => {
						dispatch({type: DirtyActionsTypes.DIRTY, payload: dirty})
					}, [dirty])
				  return (
					<Form>
						<Grid container className={classes.formRowWide}>
							<Grid>
								<InputLabel>Заключение</InputLabel>
								<Field
								  multiline
								  maxRows={4}
								  minRows={4}
								  size="small"
								  align="center"
								  type="textarea"
								  variant="outlined"
								  name={'conclusion'}
								  inputProps={textFieldProps}
								  component={TextFieldWithError}
								/>
							</Grid>

							<Grid>
								<InputLabel>Рекомендации</InputLabel>
								<Field
								  multiline
								  maxRows={4}
								  minRows={4}
								  size="small"
								  align="center"
								  type="textarea"
								  variant="outlined"
								  name={'recommendations'}
								  inputProps={textFieldProps}
								  component={TextFieldWithError}
								/>
							</Grid>
						</Grid>
						<Grid container className={classes.formRow}>

							<Grid
							  sx={{
								  '& .MuiOutlinedInput-input': {
									  padding: '8px 14px !important'
								  }
							  }}
							>
								<InputLabel>Дата</InputLabel>
								<CustomDatePicker
								  style={{width: '300px'}}
								  required
								  disableFuture
								  label="Поступило"
								  disabled={disableEdit}
								  inputFormat="dd/MM/yyyy"
								  value={new Date()}
								  views={['day', 'month', 'year']}
								  onChange={(newValue: any) =>
									setFieldValue('receivedDate', newValue)
								  }
								/>
							</Grid>

							<Grid
							  sx={{
								  '& .MuiInputBase-root': {
									  height: '3rem'
								  },
							  }}
							>
								<InputLabel>ФИО врача</InputLabel>
								<Field
								  size="small"
								  align="center"
								  type="text"
								  variant="outlined"
								  name={'user'}
								  sx={{
									  height: '3rem',
									  paddingLeft: '10px',
									  '& .MuiFormControl-root': {
										  display: 'flex',
										  justifyContent: 'center',
									  },
								  }}
								  inputProps={textFieldProps}
								  component={TextFieldWithError}
								/>
							</Grid>
						</Grid>

						{!disableEdit && <Stack
                            sx={{display: 'flex', justifyContent: 'flex-start', paddingTop: 5}}
                            direction="row"
                            spacing={2}
                        >
                            <Button variant="contained" color="success" type="submit">
                                Сохранить данные
                            </Button>
                            <Button
                                color="error"
                                variant="contained"
																onClick={() => handleNavigate(dirty, navigateToInfo)}
                            >
                                Отменить заполнение
                            </Button>
                        </Stack>}
					</Form>
				  );
			  }}
		  </Formik>
	  </Grid>
	) : null;
};

export default BreastUltrasound;
