import { HttpService } from "../../../App/services/http"
import {
	IEndocrinologistInput,
	IEndocrinologistUpdate
} from "../interfaces/endocrinologist"

export const EndocrinologistService = {
  endocrinologist,
  update,
  create,
}

function create(data: IEndocrinologistInput) {
  return HttpService.post(`conclutions/endocrinologist`, data)
    .then(
      response => {
        return response
      })
}

function endocrinologist(id: number) {
  return HttpService.get(`conclutions/endocrinologist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IEndocrinologistUpdate) {
  return HttpService.patch(`conclutions/endocrinologist`, data)
    .then(
      response => {
        return response
      })
}
