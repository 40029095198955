/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, InputLabel, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { CustomDatePicker } from '../../../App/components/Input/CustomDatePicker';
import { TextFieldWithError } from '../../../App/components/Input/TextField';
import { getDisabledForm } from "../../../App/helpers/getDisabledForm";
import { getFullName } from '../../../App/helpers/getFullName';
import { textFieldProps } from '../../../App/helpers/textField';
import { IRootState } from '../../../App/reducers/store';
import styles from '../../../shared/styles/form';
import { CancerScreeningActions } from '../actions/cancerScreening';
import { ICancerScreening, ICancerScreeningInput } from '../interfaces/cancerScreening';
import { DirtyActionsTypes } from '../../../App/interface/dirty';
import handleNavigate from '../../../App/helpers/clearButtonNavigate';

const useStyles = styles;

const CancerScreening = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const { id } = useParams();

  const { cancerScreening } = useSelector((state: IRootState) => state.cancerScreening);
  const { account } = useSelector((state: IRootState) => state.account);
  const { dirty } = useSelector((state: IRootState) => state.dirty);
  
  const [loading, setLoading] = useState(false);
  const disableEdit = getDisabledForm(account, id ?? '', location.pathname) ?? false;

  const getCancerScreening = async (id: number) => {
    await dispatch(CancerScreeningActions.cancerScreening(id));
  };

  const navigateToInfo = () => {
		navigate(`/patients/${id}/info`);
  };

  useEffect(() => {
    if (!loading && id) {
      getCancerScreening(parseInt(id)).then(
        () => setLoading(true),
        () => setLoading(true)
      );
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [id]);

	useEffect(() => {
		const beforeUnloadHandler = function () {
      return true;
    };
  
    if (dirty) {
      window.onbeforeunload = beforeUnloadHandler;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
	}, [dirty])

  const submit = (values: any) => {
    const data = {
      ...values,
      ...{
        id: cancerScreening?.id,
        idUser: account?.id,
        idPatient: Number(id),
        createdAt: cancerScreening?.createdAt,
      },
    };
    dispatch(
        cancerScreening
        ? CancerScreeningActions.update(data as ICancerScreening)
        : CancerScreeningActions.create(data as ICancerScreeningInput)
    ).then(
      () => {dispatch({type: DirtyActionsTypes.DIRTY, payload: false})},
      () => {}
    );
  };

  return loading ? (
    <Grid className={classes.main}>
      <Formik
        initialValues={{
          idPatient: cancerScreening?.idPatient,
          conclusion: cancerScreening?.conclusion,
          recommendations: cancerScreening?.recommendations,
          user: getFullName(account),
        }}
        onSubmit={submit}
      >
        {({ setFieldValue, dirty }) => {
           // eslint-disable-next-line react-hooks/rules-of-hooks
					useEffect(() => {
						dispatch({type: DirtyActionsTypes.DIRTY, payload: dirty})
					}, [dirty])
          return (
          <Form>
            <Grid container className={classes.formRowWide}>
              <Grid item>
                <InputLabel>Заключение</InputLabel>
                <Field
                  multiline
                  maxRows={4}
                  minRows={4}
                  size="small"
                  align="center"
                  type="textarea"
                  variant="outlined"
                  name={'conclusion'}
                  inputProps={textFieldProps}
								  component={TextFieldWithError}
                />
              </Grid>

              <Grid item>
                <InputLabel>Рекомендации</InputLabel>
                <Field
                  multiline
                  maxRows={4}
                  minRows={4}
                  size="small"
                  align="center"
                  type="textarea"
                  variant="outlined"
                  name={'recommendations'}
                  inputProps={textFieldProps}
                  component={TextFieldWithError}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formRow}>
              <Grid item>
                <InputLabel>Дата</InputLabel>
                <CustomDatePicker
                  required
                  disableFuture
                  disabled={disableEdit}
                  label="Поступило"
                  inputFormat="dd/MM/yyyy"
                  value={new Date()}
                  views={['day', 'month', 'year']}
                  onChange={(newValue: any) =>
                    setFieldValue('receivedDate', newValue)
                  }
                />
              </Grid>

              <Grid item>
                <InputLabel>ФИО врача</InputLabel>
                <Field
                  size="small"
                  align="center"
                  type="text"
                  variant="outlined"
                  name={'user'}
                  inputProps={textFieldProps}
                  component={TextFieldWithError}
                />
              </Grid>
            </Grid>

            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                paddingTop: 5,
              }}
              direction="row"
              spacing={2}
            >
              <Button variant="contained" color="success" type="submit">
                Сохранить данные
              </Button>
              <Button
                color="error"
                variant="contained"
                onClick={() => handleNavigate(dirty, navigateToInfo)}
              >
                Отменить заполнение
              </Button>
            </Stack>
          </Form>
        )}}
      </Formik>
    </Grid>
  ) : null;
};

export default CancerScreening;
