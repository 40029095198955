import {
	AlertActionsTypes,
	IAlertAction,
	IAlertsState
} from "../interface/alert";


const initialState: IAlertsState = {
  alerts: []
}

export const AlertReducer = (state: IAlertsState = initialState, action: IAlertAction): IAlertsState => {
  switch (action.type) {
    case AlertActionsTypes.SUCCESS:
    case AlertActionsTypes.WARNING:
    case AlertActionsTypes.ERROR:
      action.payload.id = state.alerts.length + 1
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      action.payload.type = action.type.toLowerCase().replace('alert_', '')
      return {
        ...state,
        ...{
          alerts: [...state.alerts, action.payload]
        }
      } as IAlertsState;
    case AlertActionsTypes.CLEAR:
      return {
        ...state,
        ...{
          alerts: []
        }
      }
    case AlertActionsTypes.CLOSE:
      return {
        ...state,
        ...{
          alerts: state.alerts.filter((alert) => alert.id !== action.payload.id)
        }
      }
    default:
      return state
  }
}
