import Actions from '../../../App/interface/actions';
import {
	IUserState,
	UserActionsTypes
} from '../interfaces/user';

const init: IUserState = {
  user: null,
  users: null,
};

export function UserReducer(
  state: IUserState = init,
  action: Actions
): IUserState {
  switch (action.type) {
    case UserActionsTypes.USERS:
      return {
        ...state,
        ...{
          users: action.payload,
        },
      };
      case UserActionsTypes.USERS_FILTER:
        return {
          ...state,
          ...{
            users:  action.payload
          }
        };
    case UserActionsTypes.USER:
      return {
        ...state,
        ...{
          user: action.payload,
        },
      };
    default:
      return state;
  }
}
