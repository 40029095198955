export interface IBreastUltrasoundInput {
  idPatient: number,
  conclusion: string,
  recommendations: string,
}
export interface IBreastUltrasound {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IBreastUltrasoundState {
  breastUltrasound: IBreastUltrasound | null,
}

export enum BreastUltrasoundActionsTypes {
  BREAST_ULTRASOUND='BREAST_ULTRASOUND',
}
