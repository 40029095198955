import Actions from "../../../App/interface/actions";
import {
	BloodTestActionsTypes,
	IBloodTestState
} from "../interfaces/bloodTest";


const init: IBloodTestState = {
  bloodTest: null,
};

export function BloodTestReducer(state: IBloodTestState = init, action: Actions): IBloodTestState {
  switch (action.type) {
    case BloodTestActionsTypes.BLOOD_TEST:
      return {
        ...state,
        ...{
          bloodTest: action.payload
        }
      };
    default:
      return state;
  }
}