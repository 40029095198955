import {
	AppBar,
	Button,
	Container,
	Toolbar,
	Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getActiveStyle from "../../../App/helpers/activeStyleOrPath";
import { IRootState } from "../../../App/reducers/store";
import Logo from "../../assets/logo2.png";
import { getHeaderTabs, } from '../../data/HeaderTabs';
import { UserMenu } from "./UserBox/UserMenu";
import { IPage } from "../../../App/interface/page";
import { DirtyActionsTypes } from "../../../App/interface/dirty";

const useStyles = makeStyles(() => ({
    app_bar: {
        borderBottom: "1px solid #e0e0e1",
        zIndex: 1000,
        background: "white",
        boxShadow: "none",
    },
    toolbar: {
        height: "70px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        '& >div': {
            height: "100%",
        },
        '& >div:nth-child(1)': {
            '@media(max-width: 640px)': {
                width: "70px"
            }
        },
        '& >div:nth-child(2)': {
            flexGrow: 1,
        },
        '@media(max-width: 1024px)': {
            height: "60px"
        }
    },
    parent: {
        fontWeight: 600,
        textDecoration: "none",
        color: "black",
        fontSize: "18px",
        lineHeight: "1.6",
        fontFamily: "Open Sans",
        background: "white",
        marginLeft: "12px",
        paddingTop: "3px",
        "&:hover": {
            background: "white",
        },
        '@media(max-width: 1024px)': {
            marginLeft: "6px",
            marginRight: "0"
        },
    },
    logoBlock: {
        width: "300px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        '@media(max-width: 1024px)': {
            width: "260px"
        },
        '@media(max-width: 768px)': {
            width: "180px"
        },
        '@media(max-width: 640px)': {
            width: "90px",
        },
        '& img': {
            '@media(max-width: 640px)': {
                width: "40px",
            },
        }
    },
    titleBlock: {
        marginLeft: "10px",
        '@media(max-width: 640px)': {
            display: "none"
        }
    },
    title: {
        fontWeight: 500,
        lineHeight: 1,
        textDecoration: "none",
        fontSize: "20px",
        paddingTop: "4px",
        marginBottom: "5px",
        '@media(max-width: 1024px)': {
            fontSize: "16px",
            textAlign: "left"
        }
    },
    subtitle: {
        fontWeight: 400,
        lineHeight: 1,
        textTransform: "none",
        fontSize: "12px",
        '@media screen and (max-width: 1024px)': {
            textAlign: "left"
        },
        '@media screen and (max-width: 768px)': {
            display: "none"
        }
    },
    menu_item: {
        width: "100%",
        height: "4px",
        transition: "0.2s"
    },
    active_menu_item: {
        background: "#2595FF",
        borderRadius: "0px 0px 4px 4px"
    },
    tabsContainer: {
            flexWrap: "nowrap"
    },
    menu_item_button: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        color: "#222",
        textDecoration: "none",
        padding: "10px 8px",
        marginTop: "16px",
        marginBottom: "16px",
        display: "block",
        textTransform: "none",
        "&:hover": {
            background: "transparent"
        },
        '@media(max-width: 1024px)': {
            padding: "6px 8px"
        },
        '@media(max-width: 768px)': {
            padding: "10px 8px"
        },
        '@media(max-width: 640px)': {
            padding: "6px 8px"
        },
    },
    active_menu_item_button: {
        "color": "#2595FF",
    },
    upload_manager: {
        "margin-right": "22px"
    },
    upload_manager_icon: {
        width: "32px",
        height: "32px",
    },
    upload_manager_rotate: {
        width: "32px",
        height: "32px",
        transition: "all 1s ease",
        animation: "rotate-counter-clockwise 1s infinite linear",
    },
    container: {
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    menuItemText: {
        fontSize: "14px",
        lineHeight: "12px",
        '@media screen and (max-width: 768px)': {
            // whiteSpace: "nowrap"
            // textOverflow: "ellipsis",
            fontSize: "13px",
            lineHeight: "10px"
        }
    }
}));

const Header = () => {
    const classes = useStyles();
    const dispatch: any = useDispatch();

    const {dirty} = useSelector((state: IRootState) => state.dirty)
    const {account} = useSelector((state: IRootState) => state.account)
    const navigate = useNavigate();
    const headerTabs = getHeaderTabs(account?.role ?? '')
    const goHome = () => {
        navigate('/patients/list')
    }

    const homePage = {
        "name": "Реестр пациентов",
        "path": "/patients/list",
        "parentPath": "/patients"
    }

    const handleTabClick = (page: IPage) => {
        const confirmed = window.confirm('Перейти на эту страницу без сохранения данных?');
        if (confirmed) {
            dispatch({type: DirtyActionsTypes.DIRTY, payload: false})
            navigate(page.path);
        }
    };
    
    return (
        <AppBar
            position="fixed"
            color="transparent"
            className={classes.app_bar}
        >
            <Container className={classes.container} maxWidth={false}>
                <Toolbar disableGutters className={classes.toolbar}>
                    <div>
                        <Grid className={classes.logoBlock} item>
                            <Button
                                onClick={() => dirty ? handleTabClick(homePage) : goHome()}
                                className={classes.parent}
                                sx={{mr: 3, height: '100%', display: {xs: 'flex', md: 'flex'}}}
                            >
                                <img
                                    width="300"
                                    loading="lazy"
                                    src={Logo}
                                    alt={'Поезд здоровья'}
                                />
                            </Button>
                        </Grid>
                    </div>
                    <Grid container className={classes.tabsContainer}>
                        {headerTabs.map((page) => (
                            <Grid key={page.name}>
                                <Grid className={clsx(classes.menu_item, getActiveStyle(page, window.location.pathname, classes.active_menu_item))}/>
                                <Button
                                    sx={{my: 2, color: 'black', display: 'block'}}
                                    className={clsx(classes.menu_item_button, getActiveStyle(page.path, window.location.pathname, classes.active_menu_item_button))}
                                    onClick={() => dirty ? handleTabClick(page) : navigate(page.path)}
                                >
                                    <Typography className={classes.menuItemText}>{page.name}</Typography>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    <div><UserMenu/></div>

                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header;