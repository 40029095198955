export interface IStreet {

  name: string,
  isActual?: boolean,
  isActive?: boolean,
  typeName?: string
}

export interface IStreetState {
  streets: Array<IStreet> | [],
  street: IStreet | null,
}

export enum StreetActionsTypes {
  STREETS = 'STREETS',
  STREET = 'STREET',
}

