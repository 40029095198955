import { makeStyles } from "@mui/styles";

const styles = makeStyles(() => ({
    main: {
        height: "calc(100vh - 73px)",
        flexDirection: "row"
    },
    left: {
        flex: "200px",
        width: "200px",
        position: "relative",
        backgroundColor: "white",
        '@media(max-width: 1024px)': {
            width: "110px",
            flex: "110px",
            '& button': {
                paddingLeft: "10px"
            }
        }
    },
    right: {
        flex: "calc(100% - 200px)",
        width: "calc(100% - 200px)",
        padding: "20px",
        boxSizing: "border-box",
        background: "#f5f5f5",
        '@media(max-width: 1024px)': {
            flex: "calc(100% - 110px)",
            width: "calc(100% - 110px)",
            padding: "15px",
            '& h5': {
                fontSize: "20px",
                marginBottom: "4px"
            }
        },
        '& >.MuiCard-root': {
          height: "calc(100% - 50px)"
        }
    },
    content: {
        height: "100%",
        display: "grid",
        gridTemplateColumns: "350px 1fr"
    },
    side_menu_container: {
        display: "grid",
        gridTemplateRows: "390px 1fr",
        borderRight: "solid 1px rgba(0, 0, 0, 0.1)"
    },
    menu_container: {
        '& .MuiTabs-root': {
            borderRight: "none"
        },
    },
    filter_container: {
        height: "100%",
    },
    tabsContainer: {
        height: "100%"
    },
    tab_list: {
        height: "100%",
        width: "200px",
        paddingTop: "10px",
        position: "fixed",
        "color": "#495055",
        '@media(max-width: 1024px)': {
            width: "110px",
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            left: 0,
            width: "4px",
            backgroundColor: '#2595FF',
            borderRadius: "0px 4px 4px 0px",
        },
    },
    tab: {
        height: "40px",
        color: "#000",
        fontWeight: 400,
        fontSize: "14px",
        minHeight: "40px",
        textAlign: "left",
        fontStyle: "normal",
        textTransform: "none",
        justifyContent: "flex-start",
        '&.Mui-selected': {
            color: '#2595FF',
            backgroundColor: "#F1F9FF",
        }
    },
    table: {
        '& a': {
            color: "#0067a2",
            textDecoration: "none"
        }
    },
    // '& card': {
    //     padding: "5px"
    // }

}));

export default styles;