import { Typography } from '@mui/material';
import React from 'react';

function getDiabetesRiskComponent(result: number) {
  if (result) {
    if (result < 7) {
      return (
        <Typography>
          Результат экспресс-оценки риска наличия сахарного диабета 2 типа или предиабета:
          <span style={{color: '#018801'}}> {result} - отсутствие нарушения толерантности к глюкозе </span>  
        </Typography>
      );
    } else if (result >= 7 && result < 11) {
      return (
        <Typography>
          Результат экспресс-оценки риска наличия сахарного диабета 2 типа или предиабета:
          <span style={{color: '#ff9800'}}> {result} - высокий риск наличия предиабета </span> 
        </Typography>
      );
    } else {
      return (
        <Typography>
          Результат экспресс-оценки риска наличия сахарного диабета 2 типа или предиабета:
          <span style={{color: '#cc0d00'}}> {result} - высокий риск наличия СД 2 типа. </span> 
        </Typography>
      );
    }
  }
  
  return null;
}

export default getDiabetesRiskComponent;