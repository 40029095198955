import { HttpService } from "../../../App/services/http"
import {
	ITherapistInput,
	ITherapistUpdate
} from "../interfaces/therapist"

export const TherapistService = {
  therapist,
  update,
  create,
}

function create(data: ITherapistInput) {
  return HttpService.post(`conclutions/therapist`, data)
    .then(
      response => {
        return response
      })
}

function therapist(id: number) {
  return HttpService.get(`conclutions/therapist/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: ITherapistUpdate) {
  return HttpService.patch(`conclutions/therapist`, data)
    .then(
      response => {
        return response
      })
}
