import Actions from "../../../App/interface/actions";
import {
  IStreetState,
  StreetActionsTypes
} from "../interfaces/streets";


const init: IStreetState = {
  streets: [],
  street: null
};

export function StreetReducer(state: IStreetState = init, action: Actions): IStreetState {
  switch (action.type) {
    case StreetActionsTypes.STREETS:
      return {
        ...state,
        ...{
          streets: action.payload
        }
      };
    case StreetActionsTypes.STREET:
      return {
        ...state,
        ...{
          street: action.payload
        }
      };
    default:
      return state;
  }
}