import { Dispatch } from 'react';
import { AlertActionsTypes } from '../../../App/interface/alert';
import { FileActionsTypes } from '../interfaces/file';
import { FileService } from '../services/file';
import { LoadingActionsTypes } from '../../../App/interface/loading';

export const FileActions = {
	file,
	files,
	sendFile,
	downloadFile,
	delete: remove,
};

function sendFile(params: any, formData: any) {
	return (dispatch: Dispatch<object>) =>
	  new Promise((resolve, reject) => {
		  FileService.sendFile(params, formData).then(
			(response) => {
				resolve(response);
				dispatch({
					type: AlertActionsTypes.SUCCESS, payload: {
						message: 'Файл добавлен успешно',
						type: 'success'
					}
				})
			},
			(error) => {
				dispatch({
					type: 'ALERT_ERROR',
					payload: {
						message: error.message,
						type: 'error',
					},
				});
				reject(error.message);
			}
		  );
	  });
}

function files(id: number) {
	return (dispatch: Dispatch<object>) =>
	  new Promise((resolve, reject) => {
		  FileService.files(id).then(
			(response) => {
				dispatch({type: FileActionsTypes.FILES, payload: response});
				resolve(response);
			},
			(error) => {
				dispatch({
					type: 'ALERT_ERROR',
					payload: {
						message: error.message,
						type: 'error',
					},
				});
				reject(error.message);
			}
		  );
	  });
}

function file(id: number) {
	return (dispatch: Dispatch<object>) =>
	  new Promise((resolve, reject) => {
		  FileService.file(id).then(
			(response) => {
				resolve(response);
			},
			(error) => {
				dispatch({
					type: 'ALERT_ERROR',
					payload: {
						message: error.message,
						type: 'error',
					},
				});
				reject(error.message);
			}
		  );
	  });
}

function remove(id: number) {
	return (dispatch: Dispatch<object>) =>
	  new Promise((resolve, reject) => {
		  dispatch({type: LoadingActionsTypes.LOADING, payload: true});
		  FileService.remove(id).then(
			(response) => {
				dispatch({type: LoadingActionsTypes.LOADING, payload: false})
				resolve(response);
			},
			(error) => {
				dispatch({type: LoadingActionsTypes.LOADING, payload: false})
				dispatch({
					type: 'ALERT_ERROR',
					payload: {
						message: error.message,
						type: 'error',
					},
				});
				reject(error.message);
			}
		  );
	  });
}

function downloadFile(id: number) {
	return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
		dispatch({type: LoadingActionsTypes.LOADING, payload: true})
		return FileService.file(id)
		  .then(
			response => {
                console.error(response)
				dispatch({type: LoadingActionsTypes.LOADING, payload: false})
				const file = new File([new Blob([response.data])], "file", {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document.pdf"});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL, "_blank");
			},
			error => {
				dispatch({type: LoadingActionsTypes.LOADING, payload: false})
				dispatch({
					type: AlertActionsTypes.ERROR, payload: {
						message: error.message,
						type: 'error'
					}
				})
				reject(error)
			}
		  )
	})
}