import Actions from "../../../App/interface/actions";
import {
	EndocrinologistActionsTypes,
	IEndocrinologistState
} from "../interfaces/endocrinologist";


const init: IEndocrinologistState = {
	endocrinologist: null,
};

export function EndocrinologistReducer(state: IEndocrinologistState = init, action: Actions): IEndocrinologistState {
	switch (action.type) {
		case EndocrinologistActionsTypes.ENDOCRINOLOGIST:
			return {
				...state,
				...{
					endocrinologist: action.payload
				}
			};
		default:
			return state;
	}
}