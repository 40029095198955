import Actions from "../../../App/interface/actions";
import {
	IPatientState,
	PatientActionsTypes
} from "../interfaces/patient";


const init: IPatientState = {
  patient: null,
  patients: null,
};

export function PatientReducer(state: IPatientState = init, action: Actions): IPatientState {
  switch (action.type) {
    case PatientActionsTypes.PATIENTS:
      return {
        ...state,
        ...{
          patients:  action.payload
        }
      };
    case PatientActionsTypes.PATIENTS_FILTER:
      return {
        ...state,
        ...{
          patients:  action.payload
        }
      };
    case PatientActionsTypes.PATIENT:
      return {
        ...state,
        ...{
          patient:  action.payload
        }
      };
    default:
      return state;
  }
}