export interface IPatient {
    lastName: string,
    firstName: string,
    middleName: string,
    objectIdLocality: number,
    objectIdPlanStreet: number,
    houseNumber: string,
    buildingNumber: string,
    appartmentNumber: string,
    gender: string,
    passportSeries: string,
    passportNumber: string,
    passportIssued: string,
    passportIssuedDate: Date,
    phone: string,
    snils: string,
    birthDate: any,
    age: number,
    isElevatedSugarOnce: boolean,
    isWaistToHip: boolean,
    isArterialHypertension: boolean,
    isHigherSystolicAp: boolean,
    isHighCholesterolDrugs: boolean,
    isRelativesDiabetes: boolean,
    isPhysicalActivity: boolean,
    isHarmfulFoodFtw: boolean,
    diabetesCalculationValue: number,
    isElevatedTypeTwoDiabetes: boolean,
		isElevatedCancer:boolean,
		isSmoking: boolean,
    email: string,
    createdAt: string,
    userId: string,
    id: number,
    lastMeal: number,
    emailSendingStatus: boolean,
}

export interface IPatientState {
  patient: IPatient | null,
  patients: IPatient[] | null
}

export enum PatientActionsTypes {
  PATIENTS='PATIENTS',
  PATIENTS_FILTER='PATIENTS_FILTER',
  PATIENT='PATIENT'

}
