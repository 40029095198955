import { Dispatch } from "react";
import { AlertActionsTypes } from "../../App/interface/alert";
import { LoadingActionsTypes } from "../../App/interface/loading";
import { AccountActionsTypes } from "../interface/account";
import { AccountService } from "../services/account"

export const AccountActions = {
  account,
}

function account() {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountService.account()
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({type: AccountActionsTypes.FETCH_ACCOUNT, payload: response})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}