import axios, {
	AxiosError,
	AxiosResponse,
	RawAxiosRequestConfig
} from "axios";
import * as AppStorage from './storage';

export const HttpService = {
    http,
    get,
    post,
    patch,
    put,
    delete: remove
}

interface IErrorResponse  extends  AxiosResponse {
    data: {
        error: {
            message: string,
            errors: string[]
        }
    }
}

interface IError extends AxiosError{
    response: IErrorResponse
}

async function http(location: string, options: RawAxiosRequestConfig, authorization = true, formData = false) {
    let token: string | null = await AppStorage.get('token')

    const config: RawAxiosRequestConfig = {
        url: process.env.REACT_APP_API_URL + location,
        headers: {
            'Accept': 'application/json',
            ...(!formData ? {"Content-Type": 'application/json'} : {}),
            ...(authorization && token ? {Authorization: `Bearer ${token}`} : {})
        },
        ...options
    }

    return axios.request(config).then(
        (response) => handleResponse(response, options),
        (error: IError) => handleError(error));
}

function handleError(response: IError) {
    if (response.response) {
        throw response.response.data?.error ?? response.response.data
    }
    throw response
}

function handleResponse(response: AxiosResponse, options: RawAxiosRequestConfig) {
    let data

    switch (response.status) {
        case 204:
            return response
        case 304:
            return response
        case 401:
            AppStorage.clear()
            window.location.href = "/login"
            break
        case 409:
        case 422:
            return response.data.error
        default:
            if (options['responseType']) {
                switch (options.responseType) {
                    case 'blob':
                        data = new Blob([response.data])
                        break
                    default:
                        data = response.data
                }
            } else {
                data = response.data
            }
    }
    if (!response.status.toString().startsWith('2')) {
        return data ? data.then(Promise.reject.bind(Promise)) : response
    }

    return data
}

function post(location: string, values?: object, authorization = true, formData = false, responseType = null) {
    const options: RawAxiosRequestConfig = {
        ...{
            method: "POST",
            data: formData ? values : JSON.stringify(values)
        },
        ...(responseType ? {responseType: responseType} : {})
    }
    return HttpService.http(location, options, authorization, formData)
        .then(response => {
            return response
        })
}

function patch(location: string, values?: object, authorization = true) {
    const options: RawAxiosRequestConfig = {
        method: "PATCH",
        data: JSON.stringify(values)
    }
    return HttpService.http(location, options, authorization)
        .then(response => {
            return response
        })
}

function put(location: string, values?: object, authorization = true): Promise<AxiosResponse> {
    const options: RawAxiosRequestConfig = {
        method: "PUT",
        data: JSON.stringify(values)
    }
    return HttpService.http(location, options, authorization)
        .then(response => {
            return response
        })
}

function get(location: string, params = {}, authorization = true, isBlob = false) {
    const options: RawAxiosRequestConfig = {
        ...{
            method: "GET",
            params: params
        },
        ...(isBlob ? {responseType: 'blob'} : {})
    }
    return HttpService.http(location, options, authorization)
        .then(response => {
            return response
        })
}

function remove(location: string, params = {}, authorization = true, key = 'body') {
    const options: RawAxiosRequestConfig = {
        method: "DELETE",
        [key]: (key === 'body') ? JSON.stringify(params) : params,
    }
    return HttpService.http(location, options, authorization)
        .then(response => {
            return response
        })
}
