import { HttpService } from "../../../App/services/http"

export const McbService = {
  mcbs,
}
function mcbs(data: any) {
  return HttpService.get(`mcb`,data)
    .then(
      response => {
        return response
      })
}