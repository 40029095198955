export interface IMcb {
	id: number,
	recCode: string,
	code: string,
	name: string,
	parentId: number,
	actual: boolean
}

export interface IMcbState {
	mcbs: Array<IMcb> | [],
}

export enum McbActionsTypes {
	MCB = 'MCB',
}
