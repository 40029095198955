import { TextField } from "@mui/material";
import { fieldToTextField } from "formik-material-ui";
import { getDisabledForm } from "../../helpers/getDisabledForm";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

export const TextFieldWithError = (props) => {
    const {
        classes,
        inputProps,
        ...otherProps
    } = fieldToTextField(props)
    const {id} = useParams();
    const location = useLocation();
    const {account} = useSelector((state) => state.account)

    return (
        <TextField
            value={otherProps.value}
            error={otherProps.error}
            helperText={otherProps.helperText}
            name={otherProps.name}
            type={otherProps.type}
            classes={classes}
            InputProps={inputProps}
            {...otherProps}
            disabled={getDisabledForm(account, id, location.pathname) ?? false}
            onChange={event => {
                if (otherProps.type === 'number') {
                    if (event.target.value === '') {
                        props.form.setFieldValue(props.field.name, null)
                    } else {
                        props.form.setFieldValue(props.field.name, Number(event.target.value))
                    }
                } else {
                    props.form.setFieldValue(props.field.name, event.target.value)
                }
            }
            }
        />
    )
}
