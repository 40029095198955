import {
	Button,
	Card,
	CardContent,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import {
	Field,
	Form,
	Formik
} from 'formik';
import React from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { TextFieldWithError } from '../../../App/components/Input/TextField';

import {
	getPositionNameById,
	positions
} from '../../../App/helpers/positions';
import {
	getRoleId,
	getRoleUserName,
	roles
} from '../../../App/helpers/roles';
import { textFieldProps } from '../../../App/helpers/textField';
import { UserActions } from '../../../features/User/actions/user';
import { IUserPost } from '../../../features/User/interfaces/user';
import styles from '../../../shared/styles/form';

const useStyles = styles;

const AdminRegistrationPage = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch: any = useDispatch();

	const submit = (values: any) => {

		let data = {
			...values,
			...{
				role: typeof values.role === 'string'
				  ? getRoleId(values.role)
				  : values.role,
			},
		};
		dispatch(UserActions.create(data as IUserPost)).then(
		  () => {
			  navigate('/admin/users', {replace: true})
		  },
		  () => {
		  }
		);
	};

	const validationSchema = Yup.object().shape({
		userName: Yup.mixed().required('Обязательно к заполнению'),
		password: Yup.mixed().required('Обязательно к заполнению'),
		lastName: Yup.mixed().required('Обязательно к заполнению'),
		firstName: Yup.mixed().required('Обязательно к заполнению'),
		idFunction: Yup.mixed()
		  .when('role', {
			  is: 0,
			  then: () => Yup.number().required('Обязательно к заполнению'),
			  otherwise: () => Yup.mixed().nullable()
		  }),
		role: Yup.mixed().required('Обязательно к заполнению'),
		middleName: Yup.string().nullable(),
	});

	return (
	  <>
		  <Typography variant="h5" className={'pageTitle'}>
			  Создать пользователя
		  </Typography>

		  <Card>
			  <CardContent>
				  <Grid className={classes.main}>
					  <Formik
						initialValues={{
							userName: null,
							password: null,
							lastName: null,
							firstName: null,
							middleName: null,
							idFunction: null,
							role: null,
						}}
						validationSchema={validationSchema}
						onSubmit={submit}
					  >
						  {({errors, setFieldValue, values}) => {
							  return (
								<Form>
									<Grid className={classes.formRow}>
										<Grid>
											<InputLabel>Логин</InputLabel>
											<Field
											  required
											  type="text"
											  size="small"
											  align="center"
											  name={`userName`}
											  variant="outlined"
											  inputProps={textFieldProps}
											  helperText={errors.userName}
											  component={TextFieldWithError}
											  error={Boolean(errors.userName)}
											/>
										</Grid>
										<Grid>
											<InputLabel>Пароль</InputLabel>
											<Field
											  required
											  type="password"
											  size="small"
											  align="center"
											  name={`password`}
											  variant="outlined"
											  inputProps={textFieldProps}
											  helperText={errors.password}
											  component={TextFieldWithError}
											  error={Boolean(errors.password)}
											/>
										</Grid>
									</Grid>
									<br/>
									<Grid container className={classes.formRow}>
										<Grid>
											<InputLabel>Фамилия</InputLabel>
											<Field
											  required
											  type="text"
											  size="small"
											  align="center"
											  name={`lastName`}
											  variant="outlined"
											  inputProps={textFieldProps}
											  helperText={errors.lastName}
											  component={TextFieldWithError}
											  error={Boolean(errors.lastName)}
											/>
										</Grid>
										<Grid>
											<InputLabel>Имя</InputLabel>
											<Field
											  required
											  type="text"
											  size="small"
											  align="center"
											  variant="outlined"
											  name={`firstName`}
											  inputProps={textFieldProps}
											  helperText={errors.firstName}
											  component={TextFieldWithError}
											  error={Boolean(errors.firstName)}
											/>
										</Grid>
										<Grid>
											<InputLabel>Отчество</InputLabel>
											<Field
											  type="text"
											  size="small"
											  align="center"
											  variant="outlined"
											  name={`middleName`}
											  inputProps={textFieldProps}
											  component={TextFieldWithError}
											/>
										</Grid>
									</Grid>
									<Grid className={classes.formRow}>
										<Grid>
											<Stack direction="column">
												<label
												  style={{
													  fontSize: '1rem',
													  lineHeight: '1.4375em',
													  letterSpacing: '0.00938em',
													  color: 'rgba(0, 0, 0, 0.6)',
													  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
												  }}
												>
													Роль
												</label>
												<FormControl
												  size="small"
												  variant="outlined"
												  sx={{
													  width: '230px',
												  }}
												>
													<Select
													  required
													  id="role"
													  displayEmpty
													  disableUnderline
													  value={values.role}
													  labelId="role-label"
													  renderValue={(value) => {
														  if (typeof value === 'number') {
															  return getRoleUserName(value);
														  } else {
															  return value;
														  }
													  }}
													  onChange={(e) => {
														  setFieldValue('role', e.target.value);
													  }}
													>
														{roles.map((role) => (
														  <MenuItem key={role.id} value={role.id}>
															  {role.name}
														  </MenuItem>
														))}
													</Select>
													<FormHelperText
													  sx={{color: '#F44336'}}>{errors.role}</FormHelperText>
												</FormControl>
											</Stack>
										</Grid>
										<Grid>
											<Stack direction="column">
												<label
												  style={{
													  fontSize: '1rem',
													  lineHeight: '1.4375em',
													  letterSpacing: '0.00938em',
													  color: 'rgba(0, 0, 0, 0.6)',
													  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
												  }}
												>
													Должность
												</label>
												<FormControl
												  size="small"
												  variant="outlined"
												  sx={{
													  width: '230px',
												  }}
												>
													<Select
													  required={
														(typeof values.role === 'string' &&
														  values.role === 'MedicalStaff') ||
														(typeof values.role === 'number' &&
														  values.role === 0)
													  }
													  disabled={
														(typeof values.role === 'string' &&
														  values.role !== 'MedicalStaff') ||
														(typeof values.role === 'number' &&
														  values.role !== 0)
													  }
													  id="idFunction"
													  displayEmpty
													  value={values.idFunction}
													  disableUnderline
													  labelId="idFunction-label"
													  renderValue={(value) =>
														getPositionNameById(values.role, value)
													  }
													  onChange={(e) => {
														  setFieldValue('idFunction', e.target.value);
													  }}
													>
														{positions.map((position) => (
														  <MenuItem key={position.id} value={position.id}>
															  {position.userName}
														  </MenuItem>
														))}
													</Select>
													{((typeof values.role === 'string' && values.role === 'MedicalStaff') || (typeof values.role === 'number' && values.role === 0))
													  &&
                                                        <FormHelperText
                                                            sx={{color: '#F44336'}}>{errors?.idFunction}</FormHelperText>
													}
												</FormControl>
											</Stack>
										</Grid>
									</Grid>
									<Grid
									  sx={{
										  gap: '1rem',
										  height: '80px',
										  display: 'flex',
										  padding: '1rem',
									  }}
									></Grid>
									<Stack
									  sx={{display: 'flex', justifyContent: 'center', padding: 3}}
									  direction="row"
									  spacing={2}
									>
										<Button variant="contained" color="success" type="submit"
												onClick={() => console.error(values)}>
											Сохранить данные
										</Button>
										<Button
										  color="error"
										  variant="contained"
										  onClick={() => navigate('/admin/users')}
										>
											Отменить заполнение
										</Button>
									</Stack>
								</Form>
							  )
						  }}
					  </Formik>
				  </Grid>
			  </CardContent>
		  </Card>
	  </>
	);
};
export default AdminRegistrationPage;
