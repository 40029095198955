import Actions from "../../../App/interface/actions";
import {
	ITherapistState,
	TherapistActionsTypes
} from "../interfaces/therapist";


const init: ITherapistState = {
  therapist: null,
};

export function TherapistReducer(state: ITherapistState = init, action: Actions): ITherapistState {
  switch (action.type) {
    case TherapistActionsTypes.THERAPIST:
      return {
        ...state,
        ...{
          therapist:  action.payload
        }
      };
    default:
      return state;
  }
}