import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const OnlineBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        color: '#44b700',
        backgroundColor: '#44b700',
        boxShadow: `0 0 0 2px ${ theme.palette.primary.main }`,
        '&::after': {
            top: -1,
            left: -1,
            content: '""',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            position: 'absolute',
            border: '1px solid currentColor',
            animation: 'ripple 1.2s infinite ease-in-out',
        },
    },
    '@keyframes ripple': {
        '0%': {
            opacity: 1,
            transform: 'scale(.8)',
        },
        '100%': {
            opacity: 0,
            transform: 'scale(2.4)',
        },
    },
}))

export default OnlineBadge