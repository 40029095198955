/* eslint-disable react-hooks/exhaustive-deps */
import {
	Autocomplete,
	Button,
	Grid,
	InputLabel,
	Stack,
	TextField,
} from '@mui/material';
import {
	Field,
	Form,
	Formik
} from 'formik';
import React, {
	useEffect,
	useState
} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import {
	useLocation,
	useNavigate,
	useParams
} from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { CustomDatePicker } from '../../../App/components/Input/CustomDatePicker';
import { TextFieldWithError } from '../../../App/components/Input/TextField';
import getDiabetesRiskComponent from '../../../App/helpers/getDiabetesRiscComponent';
import { getDisabledForm } from "../../../App/helpers/getDisabledForm";
import { getFullName } from '../../../App/helpers/getFullName';
import { textFieldProps } from '../../../App/helpers/textField';
import { IRootState } from '../../../App/reducers/store';
import styles from '../../../shared/styles/form';
import { McbActions } from '../../Mcb/actions/mcb';
import { IMcb } from '../../Mcb/interfaces/mcb';
import { TherapistActions } from '../actions/therapist';
import {
	ITherapistInput,
	ITherapistUpdate
} from '../interfaces/therapist';
import { DirtyActionsTypes } from '../../../App/interface/dirty';
import handleNavigate from '../../../App/helpers/clearButtonNavigate';

const useStyles = styles;

const Therapist = () => {
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();
	const {id} = useParams();

	const {mcbs} = useSelector((state: IRootState) => state.mcbs);
	const { dirty } = useSelector((state: IRootState) => state.dirty);
	const {account} = useSelector((state: IRootState) => state.account);
	const {patient} = useSelector((state: IRootState) => state.patients);
	const {therapist} = useSelector((state: IRootState) => state.therapist);
	const [loading, setLoading] = useState(false);
	const [loadingMcbs, setLoadingMcbs] = useState(false);
	const [search, setSearch] = useState('');
	const [searchQuery] = useDebounce(search, 900);
	const disableEdit = getDisabledForm(account, id ?? '', location.pathname) ?? false;

	const getTherapistConclusion = async (id: number) => {
		await dispatch(TherapistActions.therapist(id));
	};

	const navigateToInfo = () => {
		navigate(`/patients/${id}/info`);
  };

	const getMcbs = async () => {
		await dispatch(
		  McbActions.mcbs(
			searchQuery !== ''
			  ? {
				  search: searchQuery,
			  }
			  : {}
		  )
		);
	};

	useEffect(() => {
		if (!loading && id) {
			getTherapistConclusion(parseInt(id)).then(
			  () => setLoading(true),
			  () => setLoading(true)
			);
		}
	}, [loading]);

	useEffect(() => {
		if (!loadingMcbs) {
			getMcbs().then(
			  () => setLoadingMcbs(true),
			  () => setLoadingMcbs(true)
			);
		}
	}, [loadingMcbs]);

	useEffect(() => {
		if (loading) {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		setLoadingMcbs(false);
	}, [searchQuery]);

	useEffect(() => {
		const beforeUnloadHandler = function () {
      return true;
    };
  
    if (dirty) {
      window.onbeforeunload = beforeUnloadHandler;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
	}, [dirty])

	const submit = (values: any) => {
		const data = {
			...values,
			...{
				id: therapist?.id,
				idPatient: Number(id),
				mcbIds: values.mcbs.map((mcb: IMcb) => mcb.id)
			},
		};
		dispatch(
		  therapist
			? TherapistActions.update(data as ITherapistUpdate)
			: TherapistActions.create(data as ITherapistInput)
		).then(
		  () => {
				dispatch({type: DirtyActionsTypes.DIRTY, payload: false})
			  setLoading(false)
		  },
		  () => {
		  }
		);
	};

	return loading ? (
	  <Grid className={classes.main}>
		  <Formik
			initialValues={{
				mcbs: therapist?.mcbs ?? [],
				idPatient: therapist?.idPatient,
				recommendations: therapist?.recommendations,
				diabetesCalculationValue: therapist?.diabetesCalculationValue ?? '',
				user: getFullName(account),
			}}
			onSubmit={submit}
		  >
			  {({setFieldValue, values, dirty}) => {
					 // eslint-disable-next-line react-hooks/rules-of-hooks
					 useEffect(() => {
						dispatch({type: DirtyActionsTypes.DIRTY, payload: dirty})
					}, [dirty])
				  return (
					<Form>
						<Grid>
							<Grid>
								<InputLabel>Заключение МКБ</InputLabel>
								<Autocomplete
								  disablePortal
								  filterSelectedOptions
								  id="combo-box-demo"
								  options={mcbs}
								  multiple
								  size="small"
								  disabled={disableEdit}
								  value={values.mcbs}
								  onChange={(
									e: React.SyntheticEvent<Element, Event>,
									value: IMcb[]
								  ) => setFieldValue('mcbs', value)}
								  getOptionLabel={(option: IMcb) =>
									`${option?.code} - ${option?.name}`
								  }
								  renderInput={(params: any) => (
									<TextField
									  {...params}
									  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setSearch(e.target.value)
									  }
									/>
								  )}
								/>
							</Grid>
						</Grid>
						<Grid container className={classes.formRowWide}>
							<Grid>
								<InputLabel>Рекомендации</InputLabel>
								<Field
									multiline
									maxRows={4}
									minRows={4}
									size="small"
									align="center"
									type="textarea"
									variant="outlined"
									name={'recommendations'}
									inputProps={textFieldProps}
									component={TextFieldWithError}
								/>
							</Grid>

							<Grid>
								{patient?.diabetesCalculationValue &&
								  getDiabetesRiskComponent(patient.diabetesCalculationValue)
								}
							</Grid>
						</Grid>
						<Grid container className={classes.formRow}>
							<Grid
							  sx={{
								  '& .MuiOutlinedInput-input': {
									  padding: '8px 14px !important'
								  }
							  }}
							>
								<InputLabel>Дата</InputLabel>
								<CustomDatePicker
								  style={{width: '300px'}}
								  required
								  disabled={disableEdit}
								  disableFuture
								  label="Поступило"
								  inputFormat="dd/MM/yyyy"
								  value={new Date()}
								  views={['day', 'month', 'year']}
								  onChange={(newValue: any) =>
									setFieldValue('receivedDate', newValue)
								  }
								/>
							</Grid>

							<Grid>
								<InputLabel>ФИО врача</InputLabel>
								<Field
								  size="small"
								  align="center"
								  type="text"
								  variant="outlined"
								  name={'user'}
								  sx={{
									  height: '3rem',
									  paddingLeft: '10px',
									  '& .MuiFormControl-root': {
										  display: 'flex',
										  justifyContent: 'center',
									  },
								  }}
								  inputProps={textFieldProps}
								  component={TextFieldWithError}
								/>
							</Grid>
						</Grid>
						{!disableEdit && <Stack
                            sx={{display: 'flex', justifyContent: 'center', padding: 3}}
                            direction="row"
                            spacing={2}
                        >
                            <Button variant="contained" color="success" type="submit">
                                Сохранить данные
                            </Button>
                            <Button
                                color="error"
                                variant="contained"
																onClick={() => handleNavigate(dirty, navigateToInfo)}
                            >
                                Отменить заполнение
                            </Button>
                        </Stack>}
					</Form>
				  );
			  }}
		  </Formik>
	  </Grid>
	) : null;
};

export default Therapist;
