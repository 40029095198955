import { HttpService } from "../../../App/services/http"

export const LocalitiesService = {
  localities,
  locality
}
function localities(params: any) {
  return HttpService.get(`gar/view-localities`, params)
    .then(
      response => {
        return response
      })
}
function locality(id: number) {
  return HttpService.get(`gar/view-localities/${id}`)
    .then(
      response => {
        return response
      })
}