export interface IThyroidInput {
  idPatient: number,
  conclusion: string,
  recommendations: string,
}
export interface IThyroid {
  id: number,
  idPatient: number,
  idUser: string,
  conclusion: string,
  recommendations: string,
  createdAt: Date
}

export interface IThyroidState {
  thyroid: IThyroid | null,
}

export enum ThyroidActionsTypes {
  THYROID='THYROID',
}
