import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
	Button,
	Grid,
	IconButton
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useState } from 'react';

interface FileInputProps {
  maxFiles: number;
  label: string;
  disable: boolean;
  onSubmit: (files: File[]) => void;
}

export const FileUpload: React.FC<FileInputProps> = ({
    label,
    disable,
    maxFiles,
    onSubmit,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      const newFiles: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
          newFiles.push(fileList[i]);
      }
      setSelectedFiles(newFiles.slice(0, maxFiles));
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);
  };

  const handleSubmit = () => {
    onSubmit(selectedFiles);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id={`file-input-${label}`}
          disabled={disable}
        />
        <label htmlFor={`file-input-${label}`}>
          <Button variant="outlined" component="span" disabled={disable}>
            {label}
          </Button>
        </label>
      </Grid>

      <Grid item xs={12} sx={{ pt: 4 }}>
        {selectedFiles.map((file, index) => (
          <Grid item xs={12} key={index}>
            {file.name}
            <IconButton onClick={() => handleRemoveFile(index)}>
              <DeleteOutlineIcon style={{ color: red[500] }} />
            </IconButton>
          </Grid>
        ))}
        {!!selectedFiles.length && 
           <Button
           variant="contained"
           color="success" 
           onClick={handleSubmit}
           disabled={selectedFiles.length === 0}
         >
           Прикрепить
         </Button>
        }
     
      </Grid>
    </Grid>
  );
};
