import Actions from "../../../App/interface/actions";
import {
	ILocalityState,
	LocalityActionsTypes
} from "../interfaces/localities";


const init: ILocalityState = {
  localities: [],
  locality: null
};

export function LocalityReducer(state: ILocalityState = init, action: Actions): ILocalityState {
  switch (action.type) {
    case LocalityActionsTypes.LOCALITIES:
      return {
        ...state,
        ...{
          localities: action.payload
        }
      };
    case LocalityActionsTypes.LOCALITY:
      return {
        ...state,
        ...{
          locality: action.payload
        }
      };
    default:
      return state;
  }
}