const positions = [
	{
		userName: 'Медицинская сестра',
		type: 'Nurse',
		id: 1,
	},
	{
		userName: 'Врач УЗД',
		type: 'DoctorUd',
		id: 2,
	},
	{
		userName: 'Терапевт',
		type: 'Therapist',
		id: 4,
	},
	{
		userName: 'Эндокринолог',
		type: 'Endocrinologist',
		id: 3,
	},
];

const getPositionNameById = (role: any, id: unknown) => {
	if ((typeof role === 'string' && role !== 'MedicalStaff' && role !== 'Мед.персонал')
	  ||
	  (typeof role === 'number' && role !== 0)) {
		return null
	} else {
		const position = positions.find((position) => position.id === id);
		if (position) {
			return position.userName;
		}
	}
}

export {positions, getPositionNameById};