const roles = [
	{
		id: 0,
		name: 'Мед.персонал',
		userName: 'MedicalStaff',
		normalizedName: 'MEDICALSTAFF',
		concurrencyStamp: null,
	},
	{
		id: 1,
		name: 'Регистратор',
		userName: 'Receptionist',
		normalizedName: 'RECEPTIONIST',
		concurrencyStamp: null,
	},
	{
		id: 2,
		name: 'Администратор',
		userName: 'Admin',
		normalizedName: 'ADMIN',
		concurrencyStamp: null,
	},
];

const getRoleId = (name: string) => roles.find((role) => role.name === name)?.id ?? 2
const getRoleUserName = (id: number) => roles.find((role) => role.id === id)?.name ?? ''
const getRoleName = (name: string) => roles.find((role) => role.userName === name)?.name ?? ''

export {getRoleUserName, getRoleId, roles, getRoleName};