const ErrorPage = () => {
  return (
    <div id="error-page">
    <div>Страница не найдена</div>
    <p>
    </p>
  </div>
  )
}

export default ErrorPage;
