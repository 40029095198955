import { StreetActionsTypes } from "../interfaces/streets"
import { StreetsService } from "../services/streets"

export const StreetActions = {
  streets,
  street
}


function street(id: number) {
  return (dispatch: any) => new Promise((resolve, reject) => {
    StreetsService.street(id)
      .then(
        response => {
          dispatch({type: StreetActionsTypes.STREET, payload: response})
          resolve(response)
        },
        error => {
          dispatch({
            type: "ALERT_ERROR", payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function streets(values: any) {
  return (dispatch: any) => new Promise((resolve, reject) => {
    StreetsService.streets(values)
      .then(
        response => {
          dispatch({type: StreetActionsTypes.STREETS, payload: response})
          resolve(response)
        },
        error => {
          dispatch({
            type: "ALERT_ERROR", payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}