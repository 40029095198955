import { makeStyles } from "@mui/styles";

const styles = makeStyles(() => ({
    main: {
        padding: "20px",
        '& .MuiInputBase-root': {
            // borderRadius: '6px',
            // border: '1px solid #ccc',
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
    formRow: {
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        flexWrap: "wrap",
        marginBottom: "20px",
        '& >div': {
            width: '250px',
        }
    },
    formRowWide: {
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        flexWrap: "wrap",
        marginBottom: "20px",
        '& >div': {
            width: '100%',
        }
    }
}));
export default styles;