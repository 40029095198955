import {
	IDirtyAction,
	IDirtyState,
	DirtyActionsTypes
} from "../interface/dirty";


const initialState: IDirtyState = {
  dirty: false
}

export const DirtyReducer = (state: IDirtyState = initialState, action: IDirtyAction): IDirtyState => {
  switch (action.type) {
    case DirtyActionsTypes.DIRTY:
      return {
        dirty: action.payload
      }
    default:
      return state
  }
}