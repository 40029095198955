import Actions from "../../../App/interface/actions";
import {
	IBreastUltrasoundState,
	BreastUltrasoundActionsTypes
} from "../interfaces/breastUltrasound";


const init: IBreastUltrasoundState = {
  breastUltrasound: null,
};

export function BreastUltrasoundReducer(state: IBreastUltrasoundState = init, action: Actions): IBreastUltrasoundState {
  switch (action.type) {
    case BreastUltrasoundActionsTypes.BREAST_ULTRASOUND:
      return {
        ...state,
        ...{
          breastUltrasound:  action.payload
        }
      };
    default:
      return state;
  }
}