const handleNavigate = (isDirty: boolean, callback: () => void) => {
  if (isDirty) {
    const confirmed = window.confirm(
      'Введенные данные будут потеряны, продолжить?'
    );
    if (confirmed) {
      callback()
    }
  } else {
    callback()
  }
};

export default handleNavigate;