import { HttpService } from "../../../App/services/http"

export const StreetsService = {
  streets,
  street
}

function streets(params: any) {
  return HttpService.get(`gar/plans-streets`, params)
    .then(
      response => {
        return response
      })
}
function street(id: number) {
  return HttpService.get(`gar/plans-streets/${id}`)
    .then(
      response => {
        return response
      })
}
