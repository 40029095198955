/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Card,
	CardContent,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import {
	Field,
	Form,
	Formik
} from 'formik';
import {
	useEffect,
	useState
} from 'react';
import {
	useDispatch,
	useSelector
} from 'react-redux';
import {
	useNavigate,
	useParams
} from 'react-router-dom';
import { TextFieldWithError } from '../../../App/components/Input/TextField';
import {
	getPositionNameById,
	positions
} from '../../../App/helpers/positions';
import {
	getRoleId,
	getRoleUserName,
	getRoleName,
	roles
} from '../../../App/helpers/roles';
import { textFieldProps } from '../../../App/helpers/textField';
import { IRootState } from '../../../App/reducers/store';
import styles from '../../../shared/styles/form';
import { UserActions } from '../actions/user';
import { IUserUpdate } from '../interfaces/user';

const useStyles = styles;

export const User = () => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

    const {user} = useSelector((state: IRootState) => state.users);

    const [loading, setLoading] = useState(false);

    const getUser = async (id: string) => {
        await dispatch(UserActions.user(id));
    };

    useEffect(() => {
        if (!loading && id) {
            getUser(id).then(
            () => setLoading(true),
            () => setLoading(true)
          );
        }
      }, [id, loading]);

    useEffect(() => {
        if (loading) {
            setLoading(false);
        }
    }, [id]);

    const submit = (values: any) => {
        let data = {
            ...{
                userName: values.userName,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                middleName: values.middleName,
                idFunction: (values.role === "Мед.персонал" || values.role === 0) ? values.idFunction : null,
                role:
                    typeof values.role === 'string'
                        ? getRoleId(values.role)
                        : values.role,
            },
            ...(values.password ? {password: values.password} : {}),
        };
        dispatch(
            user
                ? UserActions.update(user.userName, data as IUserUpdate)
                : UserActions.create(data as any)
        ).then(
            () => {
                setLoading(false);
            },
            () => {
            }
        );
    };

    return loading ? (
        <>
            <Typography variant="h5" className={'pageTitle'}>
                Пользователь &laquo;<b>{user?.userName}</b>&raquo;
            </Typography>
            <Card>
                <CardContent>
                    <Grid className={classes.main}>
                        <Formik
                            initialValues={{
                                lastName: user?.lastName,
                                firstName: user?.firstName,
                                middleName: user?.middleName,
                                userName: user?.userName,
                                role: getRoleName(user?.role ?? ''),
                                idFunction: user?.idFunction,
                                password: null,
                            }}
                            onSubmit={submit}
                        >
                            {({errors, setFieldValue, values}) => (
                                <Form>
                                    <Grid className={classes.formRow}>
                                        <Grid>
                                            <InputLabel>Логин</InputLabel>
                                            <Field
                                                required
                                                type="text"
                                                size="small"
                                                align="center"
                                                variant="outlined"
                                                name={`userName`}
                                                inputProps={textFieldProps}
                                                helperText={errors.userName}
                                                component={TextFieldWithError}
                                                error={Boolean(errors.userName)}
                                            />
                                        </Grid>
                                        <Grid>
                                            <InputLabel>Пароль</InputLabel>
                                            <Field
                                                size="small"
                                                align="center"
                                                type="password"
                                                name={`password`}
                                                variant="outlined"
                                                inputProps={textFieldProps}
                                                helperText={errors.password}
                                                component={TextFieldWithError}
                                                error={Boolean(errors.password)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <Grid container className={classes.formRow}>
                                        <Grid>
                                            <InputLabel>Фамилия</InputLabel>
                                            <Field
                                                type="text"
                                                size="small"
                                                align="center"
                                                variant="outlined"
                                                name={`lastName`}
                                                inputProps={textFieldProps}
                                                helperText={errors.lastName}
                                                component={TextFieldWithError}
                                                error={Boolean(errors.lastName)}
                                            />
                                        </Grid>
                                        <Grid>
                                            <InputLabel>Имя</InputLabel>
                                            <Field
                                                type="text"
                                                size="small"
                                                align="center"
                                                variant="outlined"
                                                name={`firstName`}
                                                inputProps={textFieldProps}
                                                helperText={errors.firstName}
                                                component={TextFieldWithError}
                                                error={Boolean(errors.firstName)}
                                            />
                                        </Grid>
                                        <Grid>
                                            <InputLabel>Отчество</InputLabel>
                                            <Field
                                                type="text"
                                                size="small"
                                                align="center"
                                                variant="outlined"
                                                name={`middleName`}
                                                inputProps={textFieldProps}
                                                component={TextFieldWithError}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.formRow}>
                                        <Grid>
                                            <Stack direction="column">
                                                <label
                                                    style={{
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontSize: '1rem',
                                                        lineHeight: '1.4375em',
                                                        letterSpacing: '0.00938em',
                                                    }}
                                                >
                                                    Роль
                                                </label>
                                                <FormControl
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width: '230px',
                                                    }}
                                                >
                                                    <Select
                                                        id="role"
                                                        displayEmpty
                                                        value={values.role}
                                                        disableUnderline
                                                        labelId="role-label"
                                                        renderValue={(value) => {
                                                            if (typeof value === 'string') {
                                                                return value;
                                                            } else {
                                                                return getRoleUserName(value);
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setFieldValue('role', e.target.value);
                                                        }}
                                                    >
                                                        {roles.map((role) => (
                                                            <MenuItem key={role.id} value={role.id}>
                                                                {role.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </Grid>
                                        <Grid>
                                            <Stack direction="column">
                                                <label
                                                    style={{
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontSize: '1rem',
                                                        lineHeight: '1.4375em',
                                                        letterSpacing: '0.00938em',
                                                    }}
                                                >
                                                    Должность
                                                </label>
                                                <FormControl
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width: '230px',
                                                    }}
                                                >
                                                    <Select
                                                        disabled={
                                                            (typeof values.role === 'string' &&
                                                                values.role !== 'Мед.персонал') ||
                                                            (typeof values.role === 'number' &&
                                                                values.role !== 0)
                                                        }
                                                        id="idFunction"
                                                        displayEmpty
                                                        value={values.idFunction}
                                                        disableUnderline
                                                        labelId="idFunction-label"
                                                        renderValue={(value) =>
                                                            getPositionNameById(values.role, value)
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('idFunction', e.target.value);
                                                        }}
                                                    >
                                                        {positions.map((position) => (
                                                            <MenuItem key={position.id} value={position.id}>
                                                                {position.userName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Stack
                                        sx={{display: 'flex', justifyContent: 'flex-start', paddingTop: 5}}
                                        direction="row"
                                        spacing={2}
                                    >
                                        <Button variant="contained" color="success" type="submit">
                                            Сохранить данные
                                        </Button>
                                        <Button 
                                            color="error"
                                            variant="contained"
                                            onClick={() => navigate('/admin/users')}
                                        >
                                            Отменить заполнение
                                        </Button>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </CardContent>
            </Card>
        </>
    ) : null;
};
