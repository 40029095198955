import { IMcb } from "../../Mcb/interfaces/mcb";

export interface ITherapistInput {
  idPatient: number,
  mcbIds: number[],
  recommendations: string
}
export interface ITherapist {
  id: number,
  mcbs: IMcb[],
  idUser: string,
  createdAt: Date,
  idPatient: number,
  recommendations: string,
  diabetesCalculationValue: number,
}
export interface ITherapistUpdate {
  id: number,
  mcbIds: number[],
  idPatient: number,
  recommendations: string,
}

export interface ITherapistState {
  therapist: ITherapist | null,
}

export enum TherapistActionsTypes {
  THERAPIST='THERAPIST',
}
