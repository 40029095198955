import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getRoleName, getRoleUserName, roles} from "../../App/helpers/roles";
import {getPositionNameById, positions} from "../../App/helpers/positions";
import { TextFieldWithError } from "../../App/components/Input/TextField";
import { UserActions } from "../../features/User/actions/user";
import { textFieldProps } from '../../App/helpers/textField';
import { IRootState } from "../../App/reducers/store";
import stylesLayout from "../../shared/styles/styles";
import styles from "../../shared/styles/form";
import {Field, Form, Formik} from 'formik';
import {
    CardContent,
    FormControl,
    InputLabel,
    Typography,
    MenuItem,
    Select,
    Stack,
    Grid,
    Card,
} from '@mui/material';

const useStyles = styles;
const useStylesLayout = stylesLayout;

export const Profile = () => {
    const classes = useStyles();
    const classesLayout = useStylesLayout();
    const dispatch: any = useDispatch();

    const {account} = useSelector((state: IRootState) => state.account);
    const {user} = useSelector((state: IRootState) => state.users);

    const [loading, setLoading] = useState(false);

    const getUser = async (id: string) => {
        await dispatch(UserActions.user(id));
    };

    useEffect(() => {
        if (!loading && account) {
            getUser(account?.id).then(
                () => setLoading(true),
                () => setLoading(true)
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return loading ? (
        <>
            <Grid container className={classesLayout.main}>
                <Grid item className={classesLayout.right}>
                    <Typography className={'pageTitle'} variant="h5">
                        Мой профиль
                    </Typography>
                    <Card>
                        <CardContent>
                            <Grid className={classes.main}>
                                <Formik
                                    initialValues={{
                                        lastName: user?.lastName,
                                        firstName: user?.firstName,
                                        middleName: user?.middleName,
                                        userName: user?.userName,
                                        role: getRoleName(user?.role ?? ''),
                                        idFunction: user?.idFunction,
                                        password: null
                                    }}
                                    onSubmit={() => {
                                    }}
                                >
                                    {({errors, setFieldValue, values}) => (
                                        <Form>
                                            <Grid container className={classes.formRow}>
                                                <Grid>
                                                    <InputLabel>Логин</InputLabel>
                                                    <Field
                                                        disabled
                                                        required
                                                        type="text"
                                                        size="small"
                                                        align="center"
                                                        variant="outlined"
                                                        name={`userName`}
                                                        helperText={errors.userName}
                                                        component={TextFieldWithError}
                                                        error={Boolean(errors.userName)}
                                                        inputProps={{ ...textFieldProps, disabled: 'true' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <br/>
                                            <Grid container className={classes.formRow}>
                                                <Grid>
                                                    <InputLabel>Фамилия</InputLabel>
                                                    <Field
                                                        type="text"
                                                        size="small"
                                                        align="center"
                                                        variant="outlined"
                                                        name={`lastName`}
                                                        helperText={errors.lastName}
                                                        component={TextFieldWithError}
                                                        error={Boolean(errors.lastName)}
                                                        inputProps={{ ...textFieldProps, disabled: 'true' }}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    <InputLabel>Имя</InputLabel>
                                                    <Field
                                                        disabled
                                                        type="text"
                                                        size="small"
                                                        align="center"
                                                        variant="outlined"
                                                        name={`firstName`}
                                                        helperText={errors.firstName}
                                                        component={TextFieldWithError}
                                                        error={Boolean(errors.firstName)}
                                                        inputProps={{ ...textFieldProps, disabled: 'true' }}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    <InputLabel>Отчество</InputLabel>
                                                    <Field
                                                        disabled
                                                        type="text"
                                                        size="small"
                                                        align="center"
                                                        variant="outlined"
                                                        name={`middleName`}
                                                        component={TextFieldWithError}
                                                        inputProps={{ ...textFieldProps, disabled: 'true' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formRow}>
                                                <Grid>
                                                    <Stack direction="column">
                                                        <label
                                                            style={{
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                                                                fontSize: '1rem',
                                                                lineHeight: '1.4375em',
                                                                letterSpacing: '0.00938em',
                                                            }}
                                                        >
                                                            Роль
                                                        </label>
                                                        <FormControl
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{
                                                                width: '230px',
                                                            }}
                                                        >
                                                            <Select
                                                                id="role"
                                                                displayEmpty
                                                                value={values.role}
                                                                disabled
                                                                disableUnderline
                                                                labelId="role-label"
                                                                renderValue={(value) => {
                                                                    if (typeof value === 'string') {
                                                                        return value;
                                                                    } else {
                                                                        return getRoleUserName(value);
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    setFieldValue('role', e.target.value);
                                                                }}
                                                            >
                                                                {roles.map((role) => (
                                                                    <MenuItem key={role.id} value={role.id}>
                                                                        {role.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                </Grid>
                                                <Grid>
                                                    <Stack direction="column">
                                                        <label
                                                            style={{
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                                                                fontSize: '1rem',
                                                                lineHeight: '1.4375em',
                                                                letterSpacing: '0.00938em',
                                                            }}
                                                        >
                                                            Должность
                                                        </label>
                                                        <FormControl
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{
                                                                width: '230px',
                                                            }}
                                                        >
                                                            <Select
                                                                disabled
                                                                id="idFunction"
                                                                displayEmpty
                                                                value={values.idFunction}
                                                                disableUnderline
                                                                labelId="idFunction-label"
                                                                renderValue={(value) =>
                                                                    getPositionNameById(values.role, value)
                                                                }
                                                                onChange={(e) => {
                                                                    setFieldValue('idFunction', e.target.value);
                                                                }}
                                                            >
                                                                {positions.map((position) => (
                                                                    <MenuItem key={position.id} value={position.id}>
                                                                        {position.userName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    ) : null;
};
