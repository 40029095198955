import { LocalityActionsTypes } from "../interfaces/localities";
import { LocalitiesService, } from "../services/localities"


export const LocalityActions = {
  localities,
  locality
}

function locality(id: number) {
  return (dispatch: any) => new Promise((resolve, reject) => {
    LocalitiesService.locality(id)
      .then(
        response => {
          dispatch({type: LocalityActionsTypes.LOCALITY, payload: response})
          resolve(response)
        },
        error => {
          dispatch({
            type: "ALERT_ERROR", payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function localities(values: any) {
  return (dispatch: any) => new Promise((resolve, reject) => {
    LocalitiesService.localities(values)
      .then(
        response => {
          dispatch({type: LocalityActionsTypes.LOCALITIES, payload: response})
          resolve(response)
        },
        error => {
          dispatch({
            type: "ALERT_ERROR", payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

