function getActiveStyle(page, pathname, style) {
    if (page.path === '/' && pathname !== '/') {
        return null
    } else if (typeof page['parentPath'] != 'undefined' && page.parentPath.length > 0 && pathname.includes(page.parentPath)) {
        return style
    } else if (pathname.includes(page.path)) {
        return style
    }
    return null
}

export default getActiveStyle;