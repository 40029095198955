import { FormikValues } from 'formik';
import { convertToLocalTimeToUTC } from './getUTCDate';
import { IMcb } from "../../features/Mcb/interfaces/mcb";

export const transformData = (values: FormikValues): FormikValues => {
	const data = {
		...values,
	};
	for (const key in data) {
		switch (key) {
			case 'patient':
				data[`${key}Id`] = data[key]?.id ?? null;
				delete data[key];
				break;
			case 'birthDate':
				data.birthDate = convertToLocalTimeToUTC(data[key]);
				break;
			case 'passportIssuedDate':
				data.passportIssuedDate = convertToLocalTimeToUTC(data[key]);
				break;
			case 'createdAt':
				data.createdAt = convertToLocalTimeToUTC(data[key]);
				break;
			case 'objectLocality':
				data['objectIdLocality'] = data[key]?.objectId
				delete data['objectLocality'];
				break;
			case 'objectPlanStreet':
				data['objectIdPlanStreet'] = data[key]?.objectId
				delete data['objectPlanStreet'];
				break;
			case 'gender':
				if (data[key] === 'Male') {
					data[key] = 0
				} else if (data[key] === 'Female') {
					data[key] = 1
				} else {
					data[key] = parseInt(data[key])
				}
				break;
			case 'height':
			case 'weight':
			case 'heartRate':
			case 'po2':
			case 'vgdOd':
			case 'vgdOs':
			case 'waistCircumference':
				data[key] = parseInt(data[key])
				break;
			case 'mcbs':
				data['mcbIds'] = data[key].map((mcb: IMcb) => mcb.id)
				delete data[key]
				break;
			default:
				if (data[key] === '') {
					data[key] = null;
				}
				break;
		}
	}
	return data;
};
