import { HttpService } from "../../../App/services/http"
import {
	IBreastUltrasound,
	IBreastUltrasoundInput
} from "../interfaces/breastUltrasound"

export const BreastUltrasoundService = {
  breastUltrasound,
  update,
  create,
}

function create(data: IBreastUltrasoundInput) {
  return HttpService.post('medical-researches/ultrasound-mammary-gland', data)
    .then(
      response => {
        return response
      })
}

function breastUltrasound(id: number) {
  return HttpService.get(`medical-researches/ultrasound-mammary-gland/${id}`)
    .then(
      response => {
        return response
      })
}

function update(data: IBreastUltrasound) {
  return HttpService.patch('medical-researches/ultrasound-mammary-gland', data)
    .then(
      response => {
        return response
      })
}
