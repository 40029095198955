import ruLocale from 'date-fns/locale/ru';
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import {
    DesktopDatePicker,
    LocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const CustomDatePicker = (props) => {
    const {
        value,
        onChange,
        style,
        label,
        required,
        views,
        inputFormat,
        disableFuture,
        disabled
    } = (props)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
            <DesktopDatePicker
                value={value}
                onChange={onChange}
                disableFuture={disableFuture}
                views={views}
                disabled={disabled}
                format={inputFormat ?? "dd/MM/yyyy"}
                renderInput={({inputRef, inputProps, InputProps, error}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        style={style}
                    >
                        <TextField
                            label={label}
                            required={required ?? false}
                            variant="standard"
                            ref={inputRef}
                            placeholder="Выберите дату"
                            {...inputProps}
                            InputProps={
                                {
                                    ...InputProps,
                                    ...{
                                        // disableUnderline: true,
                                    }
                                }
                            }
                        />
                    </Box>
                )}
            />
        </LocalizationProvider>
    )
}
