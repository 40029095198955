import { Dispatch } from "react"
import { AlertActionsTypes } from "../../../App/interface/alert"
import { LoadingActionsTypes } from "../../../App/interface/loading"
import { IPatientInput } from "../interfaces/input/patient"
import { PatientActionsTypes } from "../interfaces/patient"
import { PatientService } from "../services/patient"


export const PatientActions = {
    patients,
    patient,
    create,
    update,
    sendConclusion,
    printConclusion,
    printPersonalInformation,
    validate,
}

  
function create(data: IPatientInput) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return PatientService.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Пациент добавлен успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function patients(values: any) {
  return (dispatch: any) => new Promise((resolve, reject) => {
    PatientService.patients(values)
          .then(
              response => {
                  dispatch({type: PatientActionsTypes.PATIENTS, payload: response})
                  resolve(response)
              },
              error => {
                  dispatch({
                      type: "ALERT_ERROR", payload: {
                          message: error.message,
                          type: 'error'
                      }
                  })
                  reject(error.message)
              }
          )
  })
}

function patient(id: number) {
  return (dispatch: Dispatch<object>) => new Promise<void>((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return PatientService.patient(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({type: PatientActionsTypes.PATIENT, payload: response})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error.message)
        }
      )
  })
}

function update(id: number, data: IPatientInput) {
  return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return PatientService.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS, payload: {
              message: 'Данные пациента обновлены успешно',
              type: 'success'
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({
            type: AlertActionsTypes.ERROR, payload: {
              message: error.message,
              type: 'error'
            }
          })
          reject(error)
        }
      )
  })
}

function sendConclusion(id: number) {
    return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
        dispatch({type: LoadingActionsTypes.LOADING, payload: true})
        return PatientService.sendConclusion(id)
          .then(
            response => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.SUCCESS, payload: {
                        message: 'Заключение отправлено успешно',
                        type: 'success'
                    }
                })
                resolve(response)
            },
            error => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.ERROR, payload: {
                        message: error.message,
                        type: 'error'
                    }
                })
                reject(error)
            }
          )
    })
}

function printConclusion(id: number) {
    return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
        dispatch({type: LoadingActionsTypes.LOADING, payload: true})
        return PatientService.printConclusion(id)
          .then(
            response => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                const file = new File([response], "file.pdf", { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                const printWindow = window.open(fileURL, "_blank");
                if(printWindow){
                    printWindow.onload = function () {
                        printWindow.print();
                    };
                }
            },
            error => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.ERROR, payload: {
                        message: error.message,
                        type: 'error'
                    }
                })
                reject(error)
            }
          )
    })
}
function printPersonalInformation(id: number) {
    return (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
        dispatch({type: LoadingActionsTypes.LOADING, payload: true})
        return PatientService.printPersonalInformation(id)
          .then(
            response => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                const file = new File([response], "file.pdf", { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                const printWindow = window.open(fileURL, "_blank");
                if(printWindow){
                    printWindow.onload = function () {
                        printWindow.print();
                    };
                }
            },
            error => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.ERROR, payload: {
                        message: error.message,
                        type: 'error'
                    }
                })
                reject(error)
            }
          )
    })
}

function validate(data: IPatientInput) : string {
  let validatingError: string = '';

  if (!data?.email &&
      (data?.objectIdLocality == null || data?.objectIdPlanStreet == null || !data?.houseNumber)) {
        validatingError = validatingError + 'Необходимо заполнить адрес регистрации или электронную почту';
  }

  return validatingError;
}
