export interface IUser {
    id: string,
    userName: string,
    name?: string,
    lastName: string,
    firstName: string,
    middleName: string,
    idFunction: number,
    role: string,
    token: string
}

export interface IUserPost {
  username: string,
  firstName: string,
  lastName: string,
  middleName: string,
  idFunction: number,
  password: string,
  role: number,
}

export interface IUserUpdate {
  userName: string,
  firstName: string,
  lastName: string,
  middleName: string,
  idFunction: number,
  password: string, 
  role: number,
}

export interface IUserState {
user: IUser | null,
users: IUser[] | null
}

export enum UserActionsTypes {
  USERS_FILTER='USERS_FILTER',
  USERS='USERS',
  USER='USER'
}
