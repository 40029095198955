export interface FileItem {
  idPatient: number;
  fileName: string;
  type: string;
  id: number;
}



export interface IFileState {
  file: any,
  files: any | null
}

export enum FileActionsTypes {
  FILES='FILES',
  FILE='FILE'

}
