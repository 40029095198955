export interface ICancerScreeningInput {
	idPatient: number,
	conclusion: string,
	recommendations: string,
}

export interface ICancerScreening {
		id: number,
		idPatient: number,
		conclusion: string,
		recommendations: string,
		idUser: string,
		createdAt: Date,
}

export interface ICancerScreeningState {
	cancerScreening: ICancerScreening | null
}

export enum CancerScreeningActionsTypes {
	CANCER_SCREENING = 'CANCER_SCREENING',
}
