export interface ILoadingState {
  loading: boolean;
}

export interface ILoadingAction {
  type: string,
  payload: boolean
}

export enum LoadingActionsTypes {
  LOADING = 'LOADING'
}
